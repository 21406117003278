.staff-settings {
  &__container {
    margin-bottom: 40px;
    // height: 100vh;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__btn-add {
  }
  &__btn-add-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 31px;
  }
}
