.google {
    &-container {
      background-color: #ffffff;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 30px 122px 30px 30px;
      margin-top: 20px;
      border-radius: 15px;
    }
  
    &-title {
      color: #2b2b2b;
      font-weight: 700;
      font-size: 16px;
    }
  
    &-fields{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    &-descripthion {
      color: #9c9c9c;
      font-weight: 400;
      font-size: 14px;
      width: 513px;
      margin-bottom: 16px;
    }
  
    &-info {
      color: #9c9c9c;
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 16px;
      a{
          text-decoration: none;
          color: #67A8F4;
          border-bottom: 1px solid #67A8F4;
        }
    }
  }
  