.text {
  color: #9c9c9c;
  font-weight: 400;
  font-size: 12px;
}

.recharts-layer.recharts-pie-sector{
  outline: none;
}

.time {
  font-weight: 400;
  font-size: 14px;
  color: #2B2B2B;
}
