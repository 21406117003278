.revenue-banner {
  &-container {
    position: relative;
    background: linear-gradient(100.35deg, #0a3160 0%, #1969c8 100%), #ffffff;
    border-radius: 15px;
    padding: 16.5px 20px 20px 20px;
  }
  &-top {
    display: flex;
  }
  &-title {
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
  }
  &-gear {
    margin-left: 10px;
    cursor: pointer;
  }

  &-data {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 4px;
    &__sum {
      font-weight: 800;
      font-size: 24px;
      color: #ffffff;
    }
    &__percent {
      display: flex;
      align-items: center;
      color: #f56c7b;
      font-weight: 700;
      font-size: 14px;
      padding: 4px 8px;
      border-radius: 20px;
      background: linear-gradient(0deg, rgba(245, 108, 123, 0.2) 0%, rgba(245, 108, 123, 0.2) 100%),
        #fff;
        cursor: pointer;
        &_hover{
          position: absolute;
          top: -9px;
          right: +20px;
        }
    }
  }

  &-plan {
    color: #e3e3e3;
    font-weight: 400;
    font-size: 14px;
  }
}
