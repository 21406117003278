.abc-desc {
  &__container {
    position: absolute;
    right: 0px;
    z-index: 500;
    border-radius: 15px;
    background: #fff;
    box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
    padding: 10px 15px 14px 15px;
    p {
      color: #1e1e1e;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
    }
    width: 332px;
  }
  &__title {
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
  }
  &__group-a {
    span {
      color: #84d9bf;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__group-b {
    span {
      color: #ffc700;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__group-c {
    span {
      color: #f56c7b;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 15px;
  }

  &__item {
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
