.nps-category {
    &__container {
      background-color: #f4f4f4;
      border-radius: 18px;
      display: flex;
      padding: 2px 7px;
    //   margin-right: 5px;
      justify-content: center;
      align-items: center;
  
      &:last-child {
        margin-right: 0px;
      }
    }
  
    &__text {
      color: #000;
      font-size: 12px;
      font-weight: 400;
    }
  
    &__number {
      width: 13px;
      height: 13px;
      justify-content: center;
      display: flex;
      color: #1e1e1e;
      font-size: 10px;
      font-weight: 700;
      border-radius: 50%;
      letter-spacing: -0.02em;
      margin-left: 5px;
    }
  
    &__number.negative-background {
      background-color: rgba(245, 108, 123, 1);
    }
  
    &__number.neatural-background {
      background-color: rgba(196, 196, 194, 1);
    }
  
    &__number.positive-background {
      background-color: rgba(132, 217, 191, 1);
    }
  }
  