.app {
  font-family: 'Mulish';
  // overflow-y: hidden;
  // overflow-x: hidden;
  width: 100%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset !important;
  background-color: #f9f9f9 !important; /* Reset background-color to default */
  color: #f9f9f9 !important; /* Reset text color to default */
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}
