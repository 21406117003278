.foodcost-settings {
    &__container {
      background-color: #fff;
      padding: 16px 20px;
      border-radius: 15px;
    }
  
    &__header {
      h2 {
        color: #2b2b2b;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
      }
  
      p {
        color: #9c9c9c;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &__selects {
      // display: flex;
      // gap: 14px;
      // justify-content: space-between;
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1.1fr 1.1fr 0.8fr;
      grid-gap: 0px 14px;
  
      &-btn {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  