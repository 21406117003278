.statistic-write-off-diagram {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin-left: 30px;
  gap: 15px;
  li {
    color: black;
    position: relative;
    width: 296px;

    .color {
      background-color: #0a3160;
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      width: 8px;
      position: relative;
      top: 4px;
      right: 4px;
      transform: translateY(-50%);
    }
    .stars {
      color: #9c9c9c;
      font-size: 14px;
      padding-left: 5px;
      @media (max-width: 1660px) {
        font-size: 12px;
      }
    }

    .percent {
      font-size: 12px;
    }

    .statistic-write-off-diagram-text {
      color: #9c9c9c;
      font-size: 14px;
    }

    .statistic-write-off-diagram-percent {
      display: inline-flex;
      width: 39px;
      font-size: 14px;
    }
  }

  .menthion {
    &-text {
      display: flex;
      align-items: center;
      color: #9c9c9c;
    }

    &-percent {
      color: #2b2b2b;
    }
  }

  // li:nth-child(1) {
  //   order: 1;
  // }

  // li:nth-child(4) {
  //   order: 2;
  // }

  // li:nth-child(2) {
  //   order: 3;
  // }

  // li:nth-child(5) {
  //   order: 4;
  // }

  // li:nth-child(3) {
  //   order: 5;
  // }

  // li:nth-child(6) {
  //   order: 6;
  // }
}
