.upload-form {
  &__photo {
    padding: 16px;
    display: flex;
    border-radius: 15px;
    // align-items: center;
    // justify-content: center;

    &-img {
      display: flex;
      align-items: center;

      img {
        width: 105px;
        height: 105px;
        border-radius: 1000px;
        object-fit: cover;
      }
    }

    &-upload-icon {
      margin-right: 8px;
    }

    &-text {
      max-width: 230px;
      margin-bottom: 8px;
    }
    &-info {
      color: #9c9c9c;
      font-weight: 400;
      font-size: 12px;
      margin-left: 16px;
      margin-top: 10px;
      margin-right: 10px;
    }
  }

  &__photo-error {
    padding: 16px 16px 10px 16px;
    border: 1px solid #f56c7b;
    display: flex;
    border-radius: 15px;
  }
}
