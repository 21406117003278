.user-comments {
  width: 350px;
  height: 100%;
  background: #ffffff;
  border-radius: 15px;
  margin-top: 40px;
  padding: 0 8% 8% 5.1%;

  &__header {
    display: flex;
  }

  &__title {
    p {
      font-weight: 700;
      font-size: 16px;
      color: #1E1E1E;
    }
    span {
      padding-left: 10px;
    }
  }
}
