.app-header {
    &__container {
      display: flex;
      justify-content: space-between;
      // height: 40px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
  
    &__right-side {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 30px;
      padding-right: 40px;
  
      &.close {
  
      }
  
      &__img {
        border-radius: 36px;
      }
    }
  
    &__left-side {
      display: flex;
      align-items: center;
      // margin-left: 40px;
      font-family: 'Mulish';
      font-style: normal;
  
      &-text {
        font-weight: 900;
        font-size: 36px;
        line-height: 45px;
      }
  
      &-date {
        margin-left: 16px;
      }
  
      &-icon {
        margin-left: 13px;
        display: flex;
        align-items: center;
      }
    }
  
    &__help {
      color: #313940;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
  
    &__profile {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eff2f3;
      border: 3px solid #76c351;
      border-radius: 50%;
      margin-right: 20px;
      cursor: pointer;
  
      &_text {
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 20px;
      }
  
      &_img {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
  
  .app-header__contai1er {
    height: 0px;
  }
  