.revenue-banner-progress-bar {
  &__container {
    height: 17px;
    width: 100%;
    background-color: #8aafd8;
    border-radius: 50px;
    margin-top: 13px;
    margin-bottom: 20px;
  }
  &__filler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: inherit;
    text-align: center;
    transition: width 0.2s ease-in-out;
    background: linear-gradient(90deg, #f56c7b 6.85%, #ffc1c8 95.16%);
  }
  &__label {
    padding: 5px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__info {
    display: flex;
    justify-content: space-between;

    &-title {
      color: #e3e3e3;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
    }
    &-sum {
      position: relative;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
      cursor: pointer;
      &__hover {
        position: absolute;
        top: -54px;
        right: -28px;
      }
    }
  }
}
