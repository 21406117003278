.subsribe {
  &-container {
    background: url('/images/subscripthion/bg.png');
    // background: linear-gradient(to bottom, #0C4F9E, #2260A9, #5F99DE);
    // box-shadow: 0 0 10px rgba(30, 30, 30, 0.10);
    min-height: 100vh;
    padding: 0px 140px 0px 140px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 13px;

    &__title {
      color: #ffffff;
      font-weight: 900;
      font-size: 28px;
      line-height: 35px;
    }

    &__desc {
      color: #f6f6f6;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &-header {
    // display: grid;
    // grid-template-columns: 32% 36% 31%;
    display: flex;
    justify-content: center;
    height: 10%;
    padding-top: 2%;
  }

  &-btn-back {
    margin-top: 5px;
    display: flex;
    position: absolute;
    left: 100px;
    a {
      margin-left: 10px;
      border: none;
      background-color: none;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }

  &-form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
  }

  &-start {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 900;
    font-size: 28px;
  }

  &-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f6f6f6;
    font-weight: 400;
    font-size: 16px;
  }

  &-verification-privacy-policy {
    padding-bottom: 2%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    span {
      color: #67a8f4;
      border-bottom: 1px solid #67a8f4;
    }
  }
}
