.sales-report {
  &__container {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    // width: calc(100% - 100px);
    margin-bottom: 40px;
    position: relative;
  }

  &__wrapper {
    background-color: #ffffff;
    padding: 16px 20px 20px 20px;
    margin-top: 20px;
    border-radius: 15px;
    transition: height 0.3s ease-in-out;
    // &:hover {
    //   height: 320px;
    // }
  }

  &__wrapper-sales {
    background-color: #ffffff;
    padding: 16px 20px 20px 20px;
    border-radius: 15px;
    transition: height 0.3s ease-in-out;
    // height: 303px;
    // &:hover {
    //   height: 314px;
    // }
  }

  &__wrapper-revenue {
    background-color: #ffffff;
    padding: 0;
    border-radius: 15px;
    transition: height 0.3s ease-in-out;
    padding-bottom: 0px;
  }

  &__about-btn {
    opacity: 0;
    transition: all 0.3s;
    display: none;
  }

  &__about-btn-hovered {
    opacity: 1;
    display: flex;
    transition: all 0.3s;
  }

  &__wrapper-bottom-right {
    background-color: #ffffff;
    padding: 16px 20px 8px 20px;
    margin-top: 20px;
    border-radius: 15px;
  }

  &__top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }

  &__header {
    display: flex;
    gap: 20px;
    width: 100%;

    &-left-side {
      width: 35%;
    }

    &-right-side {
      width: 65%;
    }
  }

  &__middle {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    &-left-side {
      width: 50%;
    }

    &-right-side {
      width: 50%;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }

  &__bottom-container {
    display: grid;
    grid-template-columns: 49.2% 49.2%;
    gap: 20px;
  }

  &__table {
    &-header {
      margin-top: 26px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content: space-between;
    }

    &-wrapper {
      margin: 16px 0px 20px 0px;
    }
  }
}
