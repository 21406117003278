.progress-bar {
  &__container {
    height: 20px;
    width: 100%;
    background-color: #e3e3e3;
    border-radius: 50px;
    margin-top: 13px;
    margin-bottom: 5px;
  }
  &__filler {
    height: 100%;
    border-radius: inherit;
    text-align: center;
    transition: width 0.2s ease-in-out;
    background-color: #67a8f4;
  }
  &__label {
    padding: 5px;
    color: white;
    font-weight: bold;
  }
  &__info {
    display: flex;
    justify-content: flex-end;
    position: relative;

    span {
      color: #2b2b2b;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }

    &-title {
      position: absolute;
      color: #2b2b2b;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
    }
  
    &-title-max{
      position: static;
      color: #2b2b2b;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
    }

    &-sum {
      color: #2b2b2b;
      padding-left: 5px;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      padding-right: 5px;
      padding-top: 5px;
    }
  }
}

.progress-bar__info__max{

}