.tree-dots-popover {
  &__container {
    padding: 8px 14px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 6px 20px 0px rgba(85, 85, 85, 0.09);
    position: absolute;
    bottom: -65px;
    z-index: 213123;
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: -1px;
    margin-left: -189px;
  }

  &__item {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #1e1e1e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
