.user-form {
  &__container {
    background: #ffffff;
    box-shadow: 0px 4px 90px rgba(1, 29, 63, 0.4);
    border-radius: 20px;
    padding: 30px;
    width: 500px;
    @media(max-height: 711px) {
      padding: 15px 30px 15px 30px;
    }
  }


  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2b2b;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  &__photo {
    padding: 16px 16px 5px 16px;
    width: fit-content;
    border: 1px dashed #f6f6f6;
    display: flex;
    border-radius: 15px;

    &-img {
      img {
        width: 100px;
        height: 100px;
        border-radius: 1000px;
      }
    }

    &-upload-icon {
      margin-right: 8px;
    }

    &-text {
      max-width: 230px;
      margin-bottom: 8px;
    }
    &-info {
      color: #9c9c9c;
      font-weight: 400;
      font-size: 12px;
      margin-left: 16px;
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}
