.table-sales {
    &__container {
      display: grid;
      grid-template-columns: 70% 15% 15%;
      margin-top: 10px;
      border-bottom: 1px solid #f6f6f6;
      padding-bottom: 10px;
    }
  
    &__name{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #9c9c9c;
    }
  
    &__coast {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #9c9c9c;
    }
  
    &__sum {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;;
      color: #9c9c9c;
    }
  
    &__wrapper {
      border-bottom: 1px solid #f6f6f6;
      padding-bottom: 10px;
    }
  
    &__info {
      display: grid;
      grid-template-columns: 70% 15% 15%;
      margin-top: 10px;
      align-items: center;
  
      &-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #2b2b2b;
      }
  
      &-coast {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #9c9c9c;
      }
  
      &-sum {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #2b2b2b;
      }
    }
  }
  