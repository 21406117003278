.date-container {
  position: absolute;
  top: 47.5px;
  left: 16px;
  background: #ffffff;
  box-shadow: 2px 6px 20px rgba(85, 85, 85, 0.09);
  border-radius: 8px;
  display: grid;
  z-index: 1000;
  width: 390px;
}

.date-header {
}