.tooltip {
    display: flex;
    border-radius: 0.25em;
    background: #1E1E1E;
    color: #fff;
    padding: 6px 10px;
    text-align: center;
    &__label {
      color: #84d9bf;
  
      padding-right: 4px;
    }
  }
  
  .recharts-tooltip-cursor {
      stroke: #67A8F4;
      stroke-width: 1px;
      stroke-dasharray: 5 5;
    }

    .average_rating{
      height: 200px;
    }

    .content-wrapper{
      display: flex;
      justify-content: space-between;
    }

    .recharts-cartesian-axis-tick-line{
      display: none;
    }