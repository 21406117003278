.modal-common {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 25px 30px 10px 30px;

    &-close{
      cursor: pointer;
    }

    &-left {
      display: flex;
      gap: 10px;
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-right {
      cursor: pointer;
    }
  }

  &__stat {
    background-color: #f9f9f9;
    padding: 12px 30px;

    &-top {
      display: flex;
      gap: 30px;
      color: #2b2b2b;
      text-align: center;
      font-size: 24px;
      font-weight: 800;

      &-percent-change {
        background: linear-gradient(0deg, rgba(245, 108, 123, 0.2), rgba(245, 108, 123, 0.2)),
          #ffffff;
        border-radius: 20px;
        padding: 4px 8px;
        color: #f56c7b;
        display: flex;
        align-items: center;
        margin-right: 14px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        cursor: default;
      }
    }

    &-bottom {
      color: #9c9c9c;
      font-size: 14px;
      font-weight: 400;
      margin-top: 8px;

      span {
        color: #9c9c9c;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  &__info {
    background-color: #f9f9f9;
    padding: 20px 0px;

    &-text {
      color: #2b2b2b;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #2b2b2b;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &-top {
      display: flex;
      justify-content: space-between;

      &-data {
        display: flex;
        gap: 16px;
      }
    }

    &-bottom {
      margin-top: 10px;
      display: flex;
      gap: 16px;
    }
  }
}
