.staff-rating {
  &-container {
    display: flex;
    padding: 22px 0px 13px 0px;
    overflow-x: scroll;
    overflow-y: auto;
  }
  
  &-container::-webkit-scrollbar {
    height: 3px;
    border-radius: 20px;
    background: var(--dashboard-back, #f4f4f4);
  }

  &-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
}

