.table-select {
  &__header {
    display: flex;
    position: relative;
    background-color: #fff;
    padding: 6px 14px;
    gap: 10px;
    border-radius: 6px;
    color: #1e1e1e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
    width: 127px;
    cursor: pointer;
  }

  &__list {
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.08);
    z-index: 105;
    position: absolute;
    right: 40px;
    margin-top: 5px;
    background-color: #fff;
    padding: 14px 11px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__item {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      color: #d35860;
    }
  }
}

.expanded {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}
.expanded-close {
  transition: transform 0.2s ease-in-out;
}
