.tabs-revenue {
  height: auto;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 2rem;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      display: flex;
      align-items: center;
      &-title {
        color: #2b2b2b;
        font-size: 18px;
        font-weight: 700;
        margin-right: 17px;
      }
    }
  }

  ul.nav {
    width: 225px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    padding-left: 0px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  ul.nav li {
    width: 50%;
    margin: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 7px;
    color: #2b2b2b;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }
  ul.nav li:nth-child(2) {
    border-radius: 7px;
  }
  ul.nav li:hover {
    // background: rgba(50, 224, 196, 0.15);
  }
  ul.nav li.active {
    background: #fff;
    color: #2b2b2b;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }
}

.empty {
  height: 52px;
}
