.header {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 18px;
  }

  &-btns {
    display: flex;
    align-items: center;
  }

  &-dots {
    margin-left: 25px;
  }
}

custom-select {
  position: relative;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-select {
  display: flex;
  width: 110px;
  padding: 4px 6px 4px 12px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 6px;
  background: #f9f9f9;
  cursor: pointer;
}

.select-selected {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
}

.arrow-close{
 transform: rotate(0deg);
 transition: transform 0.2s ease-in-out;
}

.arrow-open{
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
 }
.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #ffffff;
  border-top: none;
  border-radius: 8px;
}

.custom-select.open .select-options {
  display: block;
}

.select-options li {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.select-options_off{
  display: none;
}
.select-options_active{
  display: flex;
}

.select-options_active__text{
  color: #3498db;
}
.select-options_off__text{
  color: black;
}