.header {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &-title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 18px;
  }

  &-btns {
    display: flex;
    align-items: center;
  }

  &-dots {
    margin-left: 25px;
  }
}

.sales-custom-select {
  display: flex;
  width: 160px;
  padding: 4px 6px 4px 12px;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 6px;
  background: #f9f9f9;
  cursor: pointer;
}

.sales-select-selected {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.arrow-close {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

.arrow-open {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}
.sales-select-options {
  position: absolute;
  top: 100%;
  left: 0%; //-59
  z-index: 999;
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-top: none;
}

.sales-custom-select.open .sales-select-options {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 6px 20px 0px rgba(85, 85, 85, 0.09);
}

.sales-select-options li {
  display: flex;
  width: 160px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
}

.select-options_off {
  display: none;
}
.select-options_active {
  display: flex;
}

.select-options_active__text {
  color: #3498db;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.select-options_off__text {
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
