.first-widget {
  &__container {
    background-color: #fff;
    border-radius: 15px;
    padding: 16px 20px 20px 20px;
  }

  &__top {
    margin-bottom: 12px;
    &-info {
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-status {
      color: #2b2b2b;
      font-size: 16px;
      font-weight: 700;

      span {
        border-radius: 4px;
        background: #79d589;
        padding: 3px 6px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-left: 10px;
      }
    }

    &-date {
      color: #9c9c9c;
      font-size: 14px;
      font-weight: 400;

      span {
        color: #2b2b2b;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  &__bottom {
    border-radius: 10px;
    background: #f0f6fe;
    padding: 20px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 9px;
    li {
      list-style: none;
      padding-left: 24px;
      position: relative;

      &::before {
        background-image: url('../../../../icons/reviewsPlatforms/check.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 24px;
        width: 24px;
        position: absolute;
        left: -4px;
      }
    }
  }
}
