.menthion-widget {
  @media(max-width: 1500px) {
    align-items: center;
}

  &-statistics{
    height: 70%;
    display: flex;

  }

  &-empty{
    height: 200px;
  }
}

