.regisrtation-comment {
    &__container {
      opacity: 0.4000000059604645;
      border-radius: 12px;
      // padding: 11px 10px;
      // margin-bottom: 10px;
      // &:first-child {
      //   margin-top: 16px;
      // }
      // &:last-child {
      //   margin-bottom: 16px;
      // }
    }
  
    &__stars {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      &-logo {
        display: flex;
        align-items: center;
      }
  
      &-left-side {
        display: flex;
        gap: 8px;
      }
  
      &-right-side {
        height: 24px;
      }
    }
  
    &__content {
      &-info {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: #9c9c9c;
      }
  
      &-text {
        margin-top: 3px;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        display: flex;
        align-items: center;
        width: 288px;
        color: #fff;
      }
    }
  }
  