.table {
  &__container {
  }

  &__header {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 32px;
    align-items: center;

    &-title {
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-delete {
      color: #9c9c9c;
      font-size: 12px;
      font-weight: 400;
      align-items: center;
      display: flex;

      &::before {
        content: '';
        position: relative;
        margin-right: 4px;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
      }
    }

    &-search {
      display: flex;
      gap: 40px;
    }
  }

  &__column {
    &-wrapper {
      display: grid;
      position: sticky;
      top: 0;
      height: 30px;
      grid-template-columns: 22.35% 13% 13.1% 13.1% 13.05% 13.11% 11.5%;
      align-items: center;
      justify-content: center;
      padding-left: 21px;
      &.sticky {
        background-color: #f6f6f6;
        position: sticky;
        top: 0;
        z-index: 100;
      }
    }
    &-title {
      color: #9c9c9c;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:first-child {
        padding-left: 0px;
      }
    }

    &-icon {
      position: relative;
      top: 4px;
    }

    &-title-wrapper {
      display: flex;
      align-items: center;
    }

    &-title-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-title-button {
      display: flex;
      border: none;
      background: '#F6F6F6';
      align-items: center;
      cursor: pointer;
    }

    &-title-button__img {
      background: #f6f6f6;
    }

    &-title-fixed {
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    gap: 10px;
  }
}

.writeOff {
  display: grid;
  grid-template-columns: 43.15% 14.27% 14.27% 14.27% 14.27%;
  align-items: center;
  justify-content: center;
}

.writeOffSecond {
  display: grid;
  grid-template-columns: 43% 19% 19% 19%;
  align-items: center;
  justify-content: center;
}
