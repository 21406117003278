.header {
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__logo {
    width: 78px;
    height: 78px;
    border-radius: 50%;
  }
}


.restarant-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 8px;

  &__text {
    font-weight: 700;
    font-size: 16px;
    padding-right: 8px;
    text-align: center;
  }

  &__star{
    margin-top: 1px;
  }

  &__rating {
    font-weight: 700;
    font-size: 16px;
  }
}
