.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0 0 0 / 60%);
  color: black;
}

.overlay {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: fixed;
  padding: 20px;
  // width: 1240px;
  // // height: 700px;
  // width: 100%;
  // height: 100%;
  border-radius: 12px;
  background: white;
  transition: 0.3s transform;
  transform: scale(0.5);
}

.opened {
  opacity: 1;
  pointer-events: auto;
  z-index: 10;

  .content {
    transform: scale(1);
  }
}

.isClosing {
  .content {
    transform: scale(0.2);
  }
}
