.switch {
  &-container {
    padding: 30px ;
  }

  &-title {
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 50px;
  }
}
