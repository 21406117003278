.circle-progress-container {
    display: flex;
    flex-direction: column;
}

.nps_text{
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
    max-width: 74px;
}