.company-info {
  &__container {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 20px;
    border-radius: 15px;
    margin-bottom: 40px;
  }

  &__title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 18px;
  }

  &__photo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: 8px;
    }
  }

  &__fields {
    border-top: 1px solid #f6f6f6;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 16px;
  }

  &__fields-one {
    border-top: 1px solid #f6f6f6;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 16px;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 20px;
      text-transform: uppercase;

      color: #9c9c9c;
    }
  }

  &__fields-middle {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 16px;

    span {
      margin-top: 15px;
    }

    &-first {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__btn-wrapper {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #f6f6f6;
  }

  &__btn {
    margin-bottom: 0px;
  }
}

.TimezonePicker{
  display: block;
}

.timezone-picker-textfield input {
  // color: #9c9c9c !important;
  outline: 0;
  outline-offset: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 15px;
  font-size: 16px;
  width: 100%;
  height: 52px;
  background: #f9f9f9;
  transition: border 0.25s;
  border: none;
  border-radius: 10px;
}
