.sidebar {
  &-main-container {
    display: flex;
    background-color: #f6f6f6;
  }

  &-main {
  }

  &-title {
    font-size: 3rem;
    display: grid;
    place-items: center;
  }

  &-logo {
    margin: 30px 30px 20px 30px;
    // margin-bottom: 30px;
    // margin-left: 35px;
    // margin-top: 30px;
  }

  /* Sidebar */
  &-wrapper {
    background: #1e1e1e;
    color: white;
    width: 200px;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-top_section {
  }

  &-logo {
    font-size: 18px;
    line-height: 0;
  }

  &-bars {
    width: 30px;
  }

  &-hide {
    display: none;
  }

  &-routes {
    // margin-top: 15px;
    display: flex;
    flex-direction: column;
  }

  &-link {
    display: flex;
    color: white;
    gap: 10px;
    padding-left: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none;

    &:hover {
      background: #252c33;
    }

    &:active {
      color: #67a8f4;
    }
  }

  &-active {
    color: #67a8f4;
  }

  &-link_text {
    margin-top: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  &-menu {
    display: flex;
    color: white;
    padding-left: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    cursor: pointer;
  }

  &-menu_item {
    display: flex;
    gap: 10px;
  }

  &-menu_container {
    display: flex;
    flex-direction: column;
  }

  .menu_container .link {
  }

  &-arrow-up {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
    margin-left: 20px;
  }

  &-arrow-down {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
    margin-left: 20px;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -13px;
    top: 36px;
    width: 28px;
    height: 28px;
    background: #1e1e1e;
    border: 1px solid #252c33;
    border-radius: 40px;
    cursor: pointer;
    z-index: 111120 !important;

    &-close {
    }

    &.open {
      & svg {
        transform: rotate(-180deg);
      }
    }

    &.close {
      @media (max-width: 768px) {
        display: block;
      }

      & svg {
        transform: rotate(0deg);
      }
    }
  }
}

.active {
  color: #67a8f4;
}
