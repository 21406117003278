.profile-popover {
  position: relative;
  &__container {
    padding: 20px;
  }

  &__open-btn {
    background: none;
  }

  &__header {
    display: flex;

    &-icon {
      border-radius: 60px;
    }

    &-info {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }

    &-name {
      font-weight: 700;
      font-size: 14px;
      color: #1e1e1e;
    }

    &-plan {
      color: #1e1e1e;
      text-align: center;
      font-size: 12px;

      span {
        background: linear-gradient(90deg, #1969c8 66.07%, #9ac9ff 99.11%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 12px;
        font-weight: 800;
      }
    }
  }

  &__btn {
    // margin-top: 15px;
    // margin-bottom: 15px;
    a {
      text-decoration: none;
    }
    &-edit {
      padding: 5px 10px;
      border: 1px solid #67a8f4;
      border-radius: 7px;
      font-size: 12px;
      color: #67a8f4;
      background: #ffff;
      width: 100%;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__icons {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0px;
      }
      p {
        margin-left: 5px;
        color: #1e1e1e;
        font-size: 14px;
        font-weight: 400;
      }

      a {
        margin-left: 5px;
        color: #1e1e1e;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
}
