@import '/src/styles/mixins.scss';

.card {
  &-wrapper {
    border: 1px solid #ffffff;
    border-radius: 15px;
    background-color: #ffffff;
    padding: 10px;
    width: 255px;
    height: 490px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    
  }

  &-banner {
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 19px;
    &.type1 {
      background: linear-gradient(110.32deg, #69aeff 0%, #cee5ff 100%);
    }

    &.type2 {
      background: linear-gradient(110.32deg, #3686e3 0%, #93c2fa 100%);
    }

    &.type3 {
      background: linear-gradient(110.32deg, #0d54a8 0%, #5e9be2 100%);
    }
    &.type4 {
      background: linear-gradient(100.35deg, #0a3160 0%, #1969c8 100%);
    }

    &__trial {
      background-color: #3dcb60;
      color: #ffffff;
      padding: 4px 6px;
      width: 102px;
      font-weight: 800;
      font-size: 10px;
      border-radius: 4px;
    }

    &__type {
      margin-top: 8px;
      margin-bottom: 4px;
      @include colorGradient(
        linear-gradient(101.34deg, #ffffff 6.4%, rgba(255, 255, 255, 0.3) 96.78%)
      );
    }

    &__price {
      font-weight: 800;
      font-size: 28px;
      color: #ffffff;

      span {
        color: #e3e3e3;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  &-advantages {
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      color: #2b2b2b;
      font-weight: 400;
      font-size: 12px;
    }
    span {
      margin-top: 3px;
      margin-right: 8px;
    }
  }

  &-more {
    color: #67a8f4;
    text-decoration: none;
    font-weight: 800;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;

    &:hover {
      opacity: 0.8;
    }
  }

  &-btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-btn-bottom {
  background-color: #67a8f4;
  font-weight: 800;
  font-size: 12px;
  padding: 13px 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  a{
    text-decoration: none;
    color: #FFFFFF;
  }
}

.someDiv{
  display: flex;

}

.someDiv:nth-child(1) .lineDiv {
  display: none;
}

.someDiv:nth-child(2) .lineDiv {
  margin-top: 2px;
  margin-left: 30px;
  height: 488px;
  width: 2px;
  background: #FFFFFF;
  opacity: 0.3;
  border-radius: 30px;
}
.someDiv:nth-child(3) .lineDiv {
  display: none;
}
.someDiv:nth-child(4) .lineDiv {
  display: none;
}