.tabs-btn {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  gap: 8px;
  border: none;
  cursor: pointer;
  align-items: center;
  max-width: 400px;
  overflow: hidden;
}
