.reviews {
  &__container {
    // height: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    height: calc(100vh - 122px);
    overflow: hidden;
    margin-bottom: 30px;
    
  }

  &__left-side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // width: 387px;
  }

  &__first-column {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 1330px) {
      width: 95%;
    }
  }

  &__second-column {
    position: relative;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 1330px) {
      flex-direction: row;
      width: 100%;
    }
  }

  &__right-side {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin !important; /* For Firefox */
    scrollbar-color: #c4c4c2 !important;

    @media (max-width: 1330px) {
      flex-direction: column;
    }

    &-up {
      display: flex;
      // height: 60%;
      gap: 20px;
      @media (max-width: 1330px) {
        flex-direction: column;
      }
    }

    &-down {
      margin-top: 20px;
      border-radius: 15px;
      // width: 100%;
      // height: 30%;
      background: #ffffff;
      @media (max-width: 1330px) {
        width: 95%;
      }
    }
  }
  &__right-side::-webkit-scrollbar {
    width: 2px;
  }

  &__right-side::-webkit-scrollbar-thumb {
    background-color: #c4c4c2;
    border-radius: 10px;
  }

  &__nps {
    width: 100%;
    height: 203px;
    background: #ffffff;
    border-radius: 15px;

    @media (max-width: 1330px) {
      flex-direction: row;
      width: 100%;
    }
  }

  &__tonality {
    display: flex;
    width: 100%;
    // height: 303px;
    height: 70%;
    background: #ffffff;
    border-radius: 15px;
  }

  &__mentions {
    width: 100%;
    height: 257px;
    background: #ffffff;
    border-radius: 15px;

    @media (max-width: 1330px) {
      height: 100%;
      width: 46%;
    }

    &-info {
      display: flex;
      align-items: center;
    }

    &-info-hover {
      margin-top: 4px;
      margin-left: 12px;
    }
  }

  &__by-stars {
    width: 100%;
    height: 296px;
    background: #ffffff;
    border-radius: 15px;

    @media (max-width: 1330px) {
      height: 100%;
      width: 46%;
    }
  }

  &__average-rating {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    height: 248px;
  }

  &__cloud-content {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__cloud-container {
    display: flex;
    flex-direction: column;
    margin: 16px 20px 20px 20px;

    &-popap{}

    &-tonality {
      display: flex;
      flex-direction: column;
      margin: 16px 20px 20px 20px;
      width: 100%;
      height: 290px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      p {
        margin-top: 8px;
      }
    }

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    &-list {
      list-style: none;
      display: flex;
      align-items: center;

      li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 15px;
        line-height: 1.5;
        padding-right: 5px;
        color: #9c9c9c;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }

      li::before {
        content: '';
        position: absolute;
        top: 2.5px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      li:nth-child(1)::before {
        background-color: #84d9bf;
      }

      li:nth-child(2)::before {
        background-color: #f56c7b;
      }

      li:nth-child(3)::before {
        background-color: #e3e3e3;
      }
    }

    &-info-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-info-top {
      display: flex;
      margin-bottom: 16px;
    }

    &-hover {
      margin-top: 4px;
    }
  }
}
