.verification-input{
    width: 50px;
    height: 70px;
    margin: 18px 5px;
    text-align: center;
    font-size: 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #F9F9F9;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}