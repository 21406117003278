.write-off {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__chart {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
  }

  &__statistics {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
