.banner-statistics__percent-right {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  cursor: pointer;

  &_text {
    border-radius: 20px;
    padding: 4px 8px 4px 6px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &_hover {
    position: absolute;
    top: -24px;
    right: -30px;
  }
}
.red {
  background: linear-gradient(0deg, rgba(245, 108, 123, 0.2) 0%, rgba(245, 108, 123, 0.2) 100%),
    #fff;
  color: #f56c7b;
}

.green {
  background: linear-gradient(0deg, rgba(132, 217, 191, 0.2) 0%, rgba(132, 217, 191, 0.2) 100%),
    #fff;
}
