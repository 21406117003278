.hover-title {
  &__container {
    position: relative;
  }

  &__title {
    cursor: pointer;
  }

  &__text {
    padding: 7px 9px;
    position: absolute;
    bottom: 25px;
    right: -25px;
    border-radius: 5px;
    background: #fff;
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.08);
    width: max-content;

    span {
      color: #67a8f4;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
