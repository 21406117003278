.slider-banner {
  &-container {
    display: flex;
    flex-direction: row;
    background: transparent;
    border-radius: 12px;
    padding: 25px 12.5px 14px 0px;
    margin-left: 12px;
    overflow-x: scroll;
  }
  &-container::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 2px;
  }

  &-container::-webkit-scrollbar:vertical {
    width: 2px;
    height: 3px;
  }

  &-container::-webkit-scrollbar:horizontal {
    height: 3.5px;
  }

  &-container::-webkit-scrollbar-thumb {
    background-color: #598ac4;
    border-radius: 10px;
  }

  &-container::-webkit-scrollbar-thumb:hover {
    background-color: #165db2;
  }

  &-container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #8fb4e0;
    opacity: 0.3;
  }
  &-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    align-self: stretch;

    &_card {
      display: flex;
      position: relative;
      width: 200px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      &_percent-box {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      &_text_title {
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        height: 31px;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &_percent_text {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
      &_sum_text {
        color: #e3e3e3;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &_sum_number {
        color: #e3e3e3;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}