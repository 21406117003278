.companies-select {
  &__container {
    position: relative;
  }

  &__select-title {
    color: #2b2b2b;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }

  &__btn {
    color: #fff;
    text-align: center;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px !important;
    padding: 4px 12px !important;
  }

  &__btn-first {
    background-color: #f9f9f9;
    color: #2b2b2b;
    text-align: center;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px !important;
    padding: 4px 12px !important;
  }

  &__title {
    align-items: center;
    position: relative;
    display: flex;
    color: #9c9c9c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10.5px;

    &-icon{
      cursor: pointer;
    }
  }

  &__select {
    position: relative;
    cursor: pointer;
    align-items: center;
    display: flex;
    gap: 10px;
    padding: 6px 14px;
    border-radius: 6px;
    background: #fff;
    width: 136px;
  }

  &__content {
    width: 437px;
    position: absolute;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 200;
    top: 40px;
    padding: 14px 11px;
    border-radius: 6px;
    background: #fff;

    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.08);
  }

  &__content-wrapper {
    display: flex;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-right: 1px solid #f6f6f6;
    padding-right: 10px;
  }

  &__list-second {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
  }

  &__item {
    display: flex;
    gap: 10px;
    align-items: center;

    &-title {
      display: flex;
      flex-direction: column;

      color: #2b2b2b;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
