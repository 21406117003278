.switch-nps {
  &-container {
    display: flex;
    align-items: center;
    padding-right: 6px;
  }

  &-text {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 400;
    margin-right: 17px;
    margin-left: 14px;
  }
}

