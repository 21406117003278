.sub-row {
  &__wrapper {
    display: grid;
    grid-template-columns: 29.63% 12.96% 12.96% 12.16% 9.72% 12.16% 9.72%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f6f6f6;
  }

  &__main-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px !important;
    margin-left: 20px;
    padding: 0px 0px 0px 20px;
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 400;


  }

  &__abc {
    position: relative;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 11px;
    gap: 1px;
  }

  &__title {
    height: 50px !important;
    border-left: 1px solid #f6f6f6;
    border-right: none;
    padding: 18px 0px 13px 15px;
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 400;

    &:nth-child(5) {
      border-left: none;
    }
    &:nth-child(7) {
      border-left: none;
    }
  }
}
