.day-result {
  &__container {
    // height: calc(100% - 40px);
    // display: flex;
    // flex-direction: column;
    // width: calc(100% - 100px);
    // margin-bottom: 40px;
    // position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }

  &__right-side {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-up {
      display: flex;
      gap: 20px;
    }
  }

  &__wrapper {
    background-color: #ffffff;
    padding: 16px 20px 20px 20px;
    margin-top: 20px;
    border-radius: 15px;
    transition: height 0.3s ease-in-out;
    min-height: 309px;
    // &:hover {
    //   height: 314px;
    // }
  }

  &__wrapper-operation {
    background-color: #ffffff;
    padding: 16.5px 20px 20px 20px;
    margin-top: 20px;
    border-radius: 15px;
    transition: height 0.3s ease-in-out;
    height: 246.5px;
    // &:hover {
    //   height: 267px;
    // }
  }

  &__about-btn {
    opacity: 0;
    transition: all 0.3s;
    display: none;
  }

  &__about-btn-hovered {
    opacity: 1;
    display: flex;
    transition: all 0.3s;
  }

  &__wrapper-bottom-right {
    background-color: #ffffff;
    padding: 20px 20px 15px 20px;
    margin-top: 20px;
    border-radius: 15px;
  }

  &__top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }

  &__header {
    display: flex;
    gap: 20px;

    &-left-side {
      width: 32%;
    }
    &-right-side {
      width: 68%;
    }
  }

  &__middle {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    &-left-side {
      width: 50%;
    }

    &-right-side {
      width: 50%;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    &-left-side {
      width: 50%;
    }

    &-right-side {
      width: 49.1%;
    }
  }
}
