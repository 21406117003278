.sales_ {
  &slider-banner {
    &-container {
      display: flex;
      overflow-x: scroll;
      padding-bottom: 23px;
    }
    &-container::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 2px;
    }

    &-container::-webkit-scrollbar:vertical {
      width: 2px;
      height: 3px;
    }

    &-container::-webkit-scrollbar:horizontal {
      height: 3.5px;
    }

    &-container::-webkit-scrollbar-thumb {
      background-color: #598ac4;
      border-radius: 10px;
    }

    &-container::-webkit-scrollbar-thumb:hover {
      background-color: #165db2;
    }

    &-container::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #8fb4e0;
      opacity: 0.3;
    }
  }
}
