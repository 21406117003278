.company {
  &__line {
    border-top: 1px solid #2b2b2b;
  }

  &__container {
    padding-left: 17px;
    padding-bottom: 30px;
    padding-top: 20px;
    display: flex;
    gap: 8px;

    &.open {
      // padding-left: 17px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;

    &.open {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &.close {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  &__logo {
    border-radius: 56px;
    img {
      width: 56px;
      height: 56px;
      border-radius: 56px;
    }

    &.open {
    }
  }

  &__name {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__format {
    color: #9c9c9c;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
