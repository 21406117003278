.profile-info {
  &__container {
    display: flex;
    margin-top: 16px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }

  &__img {
    border-radius: 1000px;
  }

  &__name {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 16px;
  }

  &__adress {
    color: #9c9c9c;
    font-weight: 400;
    font-size: 14px;
  }
}
