.container-wrapper {
  display: flex;
  justify-content: space-between;
}

.star-widget {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1500px) {
    align-items: center;
  }

  &-statistics {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
