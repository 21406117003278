.cards{
    &-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-items{
        display: flex;

        gap: 30px;
    }
}