.tabs {
  &-container {
    display: flex;
    justify-content: center;
    background-color: #f6f6f6;
    height: 32px;
    margin-top: 17px;
    margin-bottom: 20px;
    border-radius: 7px;
  }

  &-wrapper {
    display: flex;
    width: 100%;
  }

  &-stick {
  color: #E3E3E3;
  }
  &-button {
    margin: 2px 2px;
    padding: 10px 20px;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f6f6f6;
    border-radius: 7px;
    width: 100%;
    cursor: pointer;
    font-size: 12px;

    &:disabled {
      background-color: #ffffff;
      color: #2b2b2b;
    }
  }
}

.isDarkContainer {
  background-color: #e3e3e3;
}

.isDarkButton {
  border: 1px solid #e3e3e3;
  background-color: #e3e3e3;
}
