.telegram {
  &-container {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(246, 246, 246, 1);
    border-radius: 15px;
    margin-top: 20px;
  }

  &-wrapper {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 0.937fr 1fr;
    border-bottom: 1px solid #f6f6f6;
    padding: 30px 152px 30px 30px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &-title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 16px;
  }

  &-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-descripthion {
    color: #9c9c9c;
    font-weight: 400;
    font-size: 14px;
    width: 513px;
    margin-bottom: 16px;
  }

  &-info {
    color: #9c9c9c;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 16px;
    a {
      text-decoration: none;
      color: #67a8f4;
      border-bottom: 1px solid #67a8f4;
    }
  }
}
