.deviation-table {
  &__container {
    position: relative;
    bottom:-14px;
  }

  &__wrapper {
    background-color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  &__header {
    border-radius: 15px 15px 0px 0px;
    border: 1px solid #fff;
    padding: 10px 10px 14px 10px;

    &-title {
      position: relative;
      color: #1e1e1e;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
    }
    &-text {
      display: flex;
      gap: 55px;
      color: #9c9c9c;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    border-bottom: 1px solid #f6f6f6;
    align-items: center;
    padding: 17.5px 10px;
    height: 50px;
    &-num {
      color: #1e1e1e;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-percent {
      border-radius: 33px;
      background: rgba(245, 108, 123, 0.2);
      padding: 5px 8px;
      color: #f56c7b;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
