.sales-progress-bar-small {
  &__container {
    height: 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 50px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  &__filler {
    height: 100%;
    border-radius: inherit;
    text-align: center;
    transition: width 0.2s ease-in-out;
  }
  &__label {
    position: relative;
    bottom: 3px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
  }

  &__filler-completed {
    height: 100%;
    border-radius: inherit;
    text-align: center;
    transition: width 0.2s ease-in-out;
  }
  &__label-completed {
    position: relative;
    bottom: 3px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
  }

  &__info {
    display: flex;
    justify-content: space-between;

    &-title {
      color: #e3e3e3;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
    }
    &-sum {
      color: #e3e3e3;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
    }
  }
}
