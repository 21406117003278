.initial-select {
  &-options {
    position: relative;
    font-size: 16px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 14px;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 6px;

    &-label {
      margin-right: 6px;
      font-size: 14px;
      color: #1e1e1e;
    }
  }

  &-arrow {
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
    background: url('../../../icons/arrow-select.svg');
  }

  &-arrow.expanded {
    transform: rotate(180deg);
  }

  &-options-list {
    z-index: 5;
    padding: 10px 10px 8px 15px;
    position: absolute;
    top: 39px;
    right: 0px;
    // width: 320px;
    grid-column-gap: 20px;
    // grid-row-gap:10px;
    max-height: 200px;
    background-color: #ffffff;
    box-shadow: 2px 6px 20px rgba(85, 85, 85, 0.09);
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &-options-list-item {
    display: flex;
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(9) {
      width: 121px;
      justify-content: space-between;
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(8),
    &:nth-child(10) {
      width: 155px;
      justify-content: space-between;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-optionn {
    display: block;
    padding: 8px 0px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }

  &-optionn input[type='checkbox'] {
    margin-right: 5px;
    color: blue;
  }
}

.secondLabel {
  font-size: 12px;
}

.secondBg {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  cursor: pointer;
  padding: 6px 14px;
  border-radius: 6px;

  &-label {
    margin-right: 20px;
    font-size: 14px;
    color: #1e1e1e;
  }
}

.staticWidth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 6px;
  height: 36px;
  width: 150px;
  &-label {
    margin-right: 20px;
  }
}

// .isServerError {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background-color: #f9f9f9;
//   cursor: pointer;
//   padding: 6px 14px;
//   border-radius: 6px;
//   border: 1px solid #f56c7b;
// }

.isDateSelect {
  display: flex;
  flex-direction: column;
  width: 150px;
  top: 36.1px;
  margin-top: 11.5px;
}

.isDateSelectItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:nth-child(1),
  &:nth-child(3),
  &:nth-child(5),
  &:nth-child(7),
  &:nth-child(9) {
    border: none;
    width: auto;
  }

  &:nth-child(2),
  &:nth-child(4),
  &:nth-child(6),
  &:nth-child(8),
  &:nth-child(10) {
    margin-left: 0px;
    width: auto;
  }
}

.isReviews {
  z-index: 5;
  padding: 10px 10px 8px 15px;
  position: absolute;
  top: 39px;

  left: 0px;
  width: 321px;

  grid-column-gap: 20px;
  // grid-row-gap:10px;
  max-height: 200px;
  background-color: #ffffff;
  box-shadow: 2px 6px 20px rgba(85, 85, 85, 0.09);
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.isOneRow {
  z-index: 5;
  padding: 10px 10px 8px 15px;
  position: absolute;
  top: 39px;
  right: 0px;
  width: 207px;
  max-height: 200px;
  background-color: #ffffff;
  box-shadow: 2px 6px 20px rgba(85, 85, 85, 0.09);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.isOneRowItem {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.scrollable {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin !important; /* For Firefox */
  scrollbar-color: #c4c4c2 !important;
  z-index: 1;
  display: block;
}

.scrollable::-webkit-scrollbar {
  width: 2px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #c4c4c2;
  border-radius: 10px;
}

.isDefault{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background-color: #ffffff;
  cursor: initial;
  border-radius: 6px;
  height: 36px;
  width: 150px;
  &-label {
    margin-right: 20px;
  }
}
