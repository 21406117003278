.day-result {
  &__sales {
    display: flex;
    flex-direction: row;
    &__chart {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 10px;
    }

    &__statistics {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
