.login-page {
  &__container {
    height: 100vh;
    display: flex;
    background: url('../../../images/registration/bg.jpg');
    background-size: cover;
  }

  &__container_loading{
    height: 100vh;
    display: block;
  }

  &__left-side {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &_background {
      left: 0px;
      position: absolute;
      background: url('../../../images/registration/Group.png');
      height: 680px;
      width: 78px;
      opacity: 0.8;
    }
  }

  &__right-side {
    width: 50%;
    background: #ffffff;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.05);
    border-radius: 30px 0px 0px 30px;
  }
}
