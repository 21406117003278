.like-for-like {
  height: calc(100% - 40px);
  border-radius: 2rem;
  margin-top: 27px;
  margin-bottom: 30px;
  &__head {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    gap: 40px;

    &-title {
      color: #2b2b2b;

      font-size: 34px;
      font-style: normal;
      font-weight: 900;
      line-height: 45px;
    }

    &-left {
      display: flex;
      align-items: center;
      margin-top: 10px;
      &-title {
        color: #2b2b2b;
        font-size: 18px;
        font-weight: 700;
        margin-right: 17px;
      }
    }
  }

  &__nav {
    margin-bottom: 10px;
    width: 490px;
    background-color: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    padding-left: 0px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  &__nav li {
    width: 50%;
    margin: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 7px;
    color: #2b2b2b;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }
  &__navl i:nth-child(2) {
    border-radius: 7px;
  }
  &__nav li:hover {
    // background: rgba(50, 224, 196, 0.15);
  }
  &__nav li.active {
    background: #fff;
    color: #2b2b2b;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }
}

.empty {
  height: 52px;
}
