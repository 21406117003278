.table-about {
    &__container {
      display: grid;
      grid-template-columns: 70% 15% 15%;
      margin-top: 10px;
      border-bottom: 1px solid #f6f6f6;
      margin: 20px 30px 0px 30px;
      padding-bottom: 10px;
    }
  
    &__name{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #9c9c9c;
    }
  
    &__coast {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #9c9c9c;
    }
  
    &__sum {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;;
      color: #9c9c9c;
    }
  
    &__wrapper {
      border-bottom: 1px solid #f6f6f6;
      padding: 0px 30px 10px 30px;
    }
  
    &__info {
      display: grid;
      grid-template-columns: 70% 15% 15%;
      margin-top: 10px;
      align-items: center;
  
      &-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #2b2b2b;
      }
  
      &-coast {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #9c9c9c;
      }
  
      &-sum {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #2b2b2b;
      }
    }
  
    &__result {
      display: flex;
      flex-direction: column;
      gap: 11px;
      padding: 10px 30px 10px 30px;

      &-wrapper{
        display: flex;
        justify-content: space-between;
      }
      &-text {
        color: #2b2b2b;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }
      &-discount{
        color: #2b2b2b;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  