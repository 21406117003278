.revenue {
  &-list {
    list-style: none;
    display: flex;
    align-items: center;

    li {
      position: relative;
      margin-top: 18.5px;
      margin-bottom: 18.5px;
      padding-left: 15px;
      margin-left: 20px;
      line-height: 1.5;
      padding-right: 5px;
      color: #9c9c9c;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }

    li::before {
      content: '';
      position: absolute;
      top: 2.5px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    li:nth-child(1)::before {
      background-color: #84d9bf;
    }

    li:nth-child(2)::before {
      background-color: #f56c7b;
    }

    li:nth-child(3)::before {
      background-color: #e3e3e3;
    }
  }

  &__top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    padding: 16px 20px 0px 20px;
  }

  &-chart {
  }
}
