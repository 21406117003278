.input-search {

    &__container{
        position: relative;
    }
  &__input {
    width: 250px;
    border-radius: 8px;
    background: #fff;
    padding: 6px 10px 6px 14px;
    border: 1px solid transparent;
    font-size: 14px;
    height: 40px;
    color: #aeaeae;

    &:focus {
      border-color: #67a8f4;
      border: 1px solid #67a8f4;
      outline: none;
      font-size: 14px;
      color: #2b2b2b;
    }
  }

  &__icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
