.operation {
  &-container {
    border-radius: 10px;
    background: #f9f9f9;
    padding: 8px 0px 12px 12px;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  &-icon {
    position: absolute;
    right: -4px;
    top: -6px;
    border: 3px solid #fff;
    border-radius: 500px;
    background: #f9f9f9;
    padding: 8px 6px 3px 6px;
  }

  &-top {
    border-bottom: 1px solid #ffffff;
  }

  &-quantity {
    color: #1e1e1e;
    font-weight: 800;
    font-size: 22px;
  }

  &-text {
    color: #1e1e1e;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;
  }

  &-warning {
    color: #9c9c9c;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &-num-text {
    margin-top: 4px;
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
  }
}
