.operations {
  &-top {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6.5px;
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-desc {
      color: #9c9c9c;
      font-size: 12px;
      font-weight: 400;

      span {
        color: #f56c7b;
        font-size: 22px;
        font-weight: 800;
      }
    }
  }

  &-list {
    display: flex;
    gap: 15px;
    margin-top: 14px;
  }
}
