.modal-cheque__info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e3e3e3;
  padding-right: 41px;
  padding-left: 41px;
  margin: 12px 0px;

  &:last-child {
    border-right: none;
  }

  &__num {
    color: #2b2b2b;
    font-size: 24px;
    font-weight: 800;
  }

  &__title {
    color: #9c9c9c;
    font-size: 12px;
    font-weight: 400;
  }
}
