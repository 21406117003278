.period {
  &-options {
    position: relative;
    font-size: 16px;
    padding: 20px 14px 20px 14px;
  }

  &-options-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &-item {
      background: #fff;
    }
  }

  &-optionn {
    display: block;
    padding: 6px 16px;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
  }
}
