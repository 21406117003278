.react-tel-input .selected-flag .arrow {
  display: none;
}

.react-tel-input .form-control {
  background-color: #f9f9f9;
  border: none;
  width: 100%;
  height: 52px;
  border-radius: 10px;
}

.react-tel-input .flag-dropdown {
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
}

.react-tel-input .selected-flag:focus {
  background-color: #f9f9f9;
  border: none;
}

.phone-input-label {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: #1e1e1e;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #f9f9f9;
}

.react-tel-input .selected-flag {
  padding: 0px;
  width: 0px;
}

.react-tel-input .flag-dropdown.open .selected-flag :hover {
  background-color: #f9f9f9 !important;
}

.react-tel-input .selected-flag .flag {
  margin-left: 13px;
}

.react-tel-input .country-list {
  background: #ffffff;
  box-shadow: 2px 6px 20px 0px rgba(85, 85, 85, 0.09);
  border-radius: 10px;
  height: 138px;
}

.phone-input-error {
  border: 1px solid rgba(245, 108, 123, 1);
  border-radius: 10px;
}
