.month-container {
  position: relative;
  text-align: center;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    width: 120px;
  }
}
.month-wrapper {
  padding: 0px 41px 19px 41px;
}

.month-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.month-btn {
  display: flex;
  height: 32px;
  align-items: center;
  background: #67a8f4;
  border-radius: 6px;
  padding: 4px 12px;
  color: #ffffff;
  margin-top: 12px;
  border: none;
  font-size: 12px;
  cursor: pointer;

}

.month-periods {
  cursor: pointer;
  color: #67a8f4;
}

.date-title {
  display: block;
  margin-left: 18px;
  margin-right: 18px;
  color: #67a8f4;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.days-of-week {
  color: #9c9c9c;
  font-weight: 400;
  font-size: 12px;
}

.days-of-week-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  margin-bottom: 10px;
  font-size: 10px;
  text-align: center;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
}
