.custom-select {
  &-options {
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 40px;
    justify-content: center;
    z-index: 6;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 14px;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 6px;
    height: 36px;

    &-label-text-wrapper {
      display: flex;
    }
    &-label-hover-text {
      padding-left: 3px;
      font-weight: 400;
      font-size: 14px;
      color: #67a8f4;
    }
    &-label {
      margin-right: 20px;
      font-size: 14px;
      color: #1e1e1e;
    }
    &-label-text-all {
      width: 100px;
    }
  }

  &-arrow {
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
    background: url('../../../icons/arrow-select.svg');
  }

  &-arrow.expanded {
    transform: rotate(180deg);
  }

  &-options-list {
    position: absolute;
    top: 36.1px;
    left: 0;
    width: 150px;
    max-height: 250px;
    background-color: #ffffff;
    box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    padding: 14px 11px;
    margin-top: 11.5px;
  }

  &-option {
    display: flex;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  &-option input[type='checkbox'] {
    margin-right: 10px;
  }
}

.test {
  width: 84px;
  font-size: 14px;
  color: #1e1e1e;
}

.secondBg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  background-color: #f9f9f9;
  cursor: pointer;
  border-radius: 6px;
}
