.second-widget {
  &__container {
    border-radius: 15px;
    background: #fff;
    padding: 16px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__top {
    &-title {
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-desc {
      color: #9c9c9c;
      font-size: 14px;
      font-weight: 400;

      span {
        color: rgba(43, 43, 43, 1);
        font-weight: 700;
      }
    }
  }
  &__btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
