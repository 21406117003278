.popover {

  &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &-cancel{
    cursor: pointer;
    right: 9px;
    top: 5px;
    position: absolute;
  }

  &-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap:25px;
    grid-row-gap: 10px;
    padding: 10px 14px 10px 11px;
    :first-child {
      color: #9c9c9c;
    }
    li {
      list-style: none;
      color: #1e1e1e;
      font-size: 14px;
      display: flex;
    }
  }

  &-elem_container {
    display: flex;
  }

  &-title {
    padding-top: 10px;
    padding-left: 11px;
    color: #1e1e1e;
    font-weight: 700;
    font-size: 14px;
  }

  &-item {
    margin-left: 10px;
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
  }

  &-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 15px;
    padding-right: 15px;
  }

  &-save-btn {
    color: #ffffff;
    background-color: #67a8f4;
    padding: 9px 12px;
    border: none;
    border-radius: 6px;
    margin-left: 15px;
    font-weight: 400;
    font-size: 12px;
    width: 87px;
    cursor: pointer;

    &:hover{
      opacity: 0.8;
    }
  }

  &-cancel-btn {
    color: #2b2b2b;
    background-color: #f9f9f9;
    padding: 9px 12px;
    border: none;
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
    width: 80px;
    cursor: pointer;


    &:hover{
      opacity: 0.8;
    }
  }
}
