.review-item {
    &__container {
        margin-top: 12px;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid #F6F6F6;
        &:first-child{
            border-top: 1px solid #F6F6F6;
        }
    }

    &__stars {
        display: flex;
        align-items: center;
        gap: 2px
    }

    &__header {
        margin-top: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:first-child{
            margin-top: 19px;
        }

        &-logo {
            display: flex;
            align-items: center;
        }

        &-left-side {
            display: flex;
            gap: 8px;
        }

        &-right-side {
            height: 24px;
        }

        &-nps-category{
            display: flex;
            margin-top: 8px;
            flex-wrap: wrap;
            gap: 5px;
        }

        &-btn{
            background-color: #F4F4F4;
            border-radius: 18px;
            padding: 2px 7px;
            font-size: 12px;
            color: #000;
            border: none;
            cursor: pointer;
        }
    }

    &__content {
        &-info {
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #9C9C9C;
        }

        &-text {
            margin-top: 6px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }
    }
}