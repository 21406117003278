.menthion-statisctic {
  &__container {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 19%;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width: 1660px) {
    }
    li {
      color: black;
      position: relative;
      display: flex;
    }

    .menthion {
      &-text {
        display: flex;
        align-items: center;
        color: #9c9c9c;
      }

      &-percent {
        color: #2b2b2b;
      }
    }
  }

  &__list-exeption {
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width: 1660px) {
    }
    li {
      color: black;
      position: relative;
      display: flex;
    }

    .menthion {
      &-text {
        display: flex;
        align-items: center;
        color: #9c9c9c;
      }

      &-percent {
        color: #2b2b2b;
      }
    }
  }
  &__color {
    background-color: #0a3160;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    position: relative;
    top: 7px;
    right: 5px;
    transform: translateY(-50%);
    width: 8px;
  }
  &__stars {
    color: #9c9c9c;
    font-size: 14px;
    padding-left: 5px;
    @media (max-width: 1660px) {
      font-size: 12px;
    }

    &.low-rating {
      padding-left: 12.2px;
      color: #9c9c9c;
      font-size: 14px;
    }

    &.high-rating {
      color: #9c9c9c;
      font-size: 14px;
    }
    &.additional-class {
      margin-right: 2px;
    }
  }

  &__nps-type {
    color: #9c9c9c;
    font-size: 14px;
    padding-left: 5px;
    @media (max-width: 1660px) {
      font-size: 10px;
    }
  }

  &__percent {
    font-size: 12px;
  }
}
