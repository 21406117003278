.sales_slider-banner-list {
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  gap: 35px;
  align-self: stretch;

  &_card {
    display: flex;
    min-width: 145px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    &_title {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &_sum {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      &_rubl {
        font-weight: 400;
      }
    }
    &_percent {
      color: #e3e3e3;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &_bold {
        color: #e3e3e3;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
