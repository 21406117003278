.register {
  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-container {
    display: flex;
    align-items: center;
    // margin-top: 2%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 450px;
    // height: 680px;
  }

  &__fields {
    display: grid;
    gap: 16px;
    // margin-top: 59px;
    // margin-top: 30px;
    
  }

  &__text-block {
    // margin-top: 59px;
    margin-top: 86px;
    width: 400px;

    &__main {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 800;
      font-size: 27px;
      line-height: 120%;
      text-align: center;
      color: #1e1e1e;
    }

    &__additional {
      margin-top: 8px;
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 500;
      font-size: 15.5px;
      line-height: 120%;
      text-align: center;
      color: #1e1e1e;
      margin-bottom: 30px;
    }
  }

  &__create-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    background: #67a8f4;
    border-radius: 10px;
    color: #ffffff;

    width: 100%;
    height: 46px;
    padding: 7px 42px;
    margin-bottom: 30px;

    outline: none;
    border: none;

    font-weight: 800;
    font-size: 16px;
    line-height: 20px;

    :hover {
      opacity: 0.8;
      background-color: red;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    &-or {
      font-weight: 500;
      font-size: 15.5px;
      line-height: 19px;
      display: flex;
      justify-content: center;
      color: #000000;
    }

    &-underline {
      a {
        text-decoration: none;
        border-bottom: 1px solid #67a8f4;
        color: #67a8f4;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }

  &__btn{
    margin-top: 24px;
  }

  &__switch-mode {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    span{
        margin-right: 4px;
    }
  }

  &__privacy-policy {
    margin-top: 129px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #c4c4c2;

    &-underline {
      cursor: pointer;
      color: #67a8f4;
      font-weight: 600;
      border-bottom: 1px solid #67a8f4;
    }
  }

  &-error{
    margin-top: 1px;
    color: #f56c7b;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0px; 
  }
}
