.staf-settings__card {
  &-container {
    margin-top: 20px;
    margin-left: 5px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    height: 264px;
    transition: transform 0.3s ease-in-out, height 0.3s ease-in-out; 
    &:hover {
      transform: scale(1.02);
      height: 284px;
      box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
    }
  }

  &-photo {
    border-radius: 50%;
  }

  &-info {
    display: flex;
    margin-bottom: 15px;
  }

  &-person {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__name {
      color: #2b2b2b;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    &__role {
      color: #9c9c9c;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-contacts {
    border-top: 1px solid #f6f6f6;
  }

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    span {
      margin-left: 18px;
      font-weight: 400;
      font-size: 16px;
      color: #2b2b2b;
    }
  }

  &-buttons {
    margin-top: 16px;
    display: flex;
    gap: 10px;
    transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
    span {
      margin-left: 8px;
    }
  }
}
