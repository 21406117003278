.multi-select-dropdown {
  position: relative;
  background-color: #f9f9f9;
  border-radius: 6px;
  width: 110px;
}

.multi-select-dropdown__selected {
  width: 110px;
  color: #2b2b2b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 6px 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > img {
    height: 18px;
  }
}

.multi-select-dropdown__options {
  transition: all 0.2s ease;
  border-radius: 8px;
  margin-top: 7px;
  background: #fff;
  box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  display: none;
  position: absolute;
  box-sizing: border-box;
  right: 0;
  width: 207px;
  list-style: none;
  padding-left: 0px;
  padding: 5px 0px;
  z-index: 100;
  transition: all 0.2s ease;

  &.open {
    display: block;
  }
}

.openOptions {
  display: block;
}

.isScrollOptions {
  height: 271px;
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: #c4c4c2 !important;
  transition: all 0.2s ease;
  border-radius: 8px;
  margin-top: 7px;
  background: #fff;
  box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  position: absolute;
  box-sizing: border-box;
  right: 0;
  width: 207px;
  list-style: none;
  padding-left: 0px;
  padding: 5px 0px;
  z-index: 100;
  transition: all 0.2s ease;
}

.isScrollOptions::-webkit-scrollbar {
  width: 2px;
}

.isScrollOptions::-webkit-scrollbar-thumb {
  background-color: #c4c4c2;
  border-radius: 10px;
}

.multi-select-dropdown__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
  height: 40px;
  cursor: pointer;
  &:hover {
    color: #67a8f4;
  }

  &-checkbox {
    margin-right: 6px;
  }
}

.multi-select-dropdown__option-selected {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #67a8f4;
  width: 150px;
}
.multi-select-dropdown__option-title {
  color: #1e1e1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rotate-arrow {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate-arrow-down {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
