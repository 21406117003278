.hover-info-deviation {
  &__container {
  }
  &__icon {
    margin-top: 3px;
    margin-left: 10px;
    fill: #e3e3e3;
    transition: fill 0.3s ease;
    cursor: pointer;
    &:hover {
      fill: #67a8f4;
    }
  }
  &__hover__wrapper {
    display: flex;
    max-width: 171px;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    padding: 7px 9px;
    gap: 4px;
    right: 0px;
    top: 27px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  }
  &__hover__text {
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
