.tofu {
  &-container {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px 122px 30px 30px;
    margin-top: 20px;
    border-radius: 15px;
  }

  &-title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 16px;
  }

  &-title-logo {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 16px;
    margin-left: 4px;
  }

  &-wrapper {
    display: flex;
  }

  &-column {
    margin-bottom: 20px;

    &-title {
      color: #1e1e1e;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
      margin-top: 2px;
    }

    &-row {
      display: flex;
      gap: 20px;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid transparent;
        box-sizing: border-box;
        cursor: pointer;

        &.selected {
          border-color: #67a8f4;
        }
        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #1e1e1e;
          font-family: Mulish;
          font-size: 10px;
          font-weight: 400;
          margin-top: 2px;

          &.selected {
            color: #67a8f4;
          }
        }

        &-desc {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 28px;
          color: #000;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    &-format {
      background-color: #f6f6f6;
      border-radius: 10px;
      width: 63px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1e1e1e;
      font-size: 10px;
      font-weight: 700;
      padding-top: 6px;
      padding-bottom: 7px;
      border-radius: 10px;
      border: 1px solid transparent;
      box-sizing: border-box;
      cursor: pointer;

      &.selected {
        border-color: #67a8f4;
      }
    }
  }

  &-row {
    display: flex;
    gap: 10px;
  }

  &-descripthion {
    color: #9c9c9c;
    font-weight: 400;
    font-size: 14px;
    width: 513px;
    margin-bottom: 16px;
  }

  &-btns {
    padding-top: 10px;
    display: flex;
    gap: 17px;

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 6px;
    }
  }
}

.preview-btn {
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 800 !important;
}
