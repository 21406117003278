@import '../../../styles/variables.scss';

$box-shadow: 0 5px 10px;

@mixin button-common($bg-color) {
  background-color: $bg-color;
  box-shadow: $box-shadow rgba($bg-color, 0.25);

  &:hover {
    background-color: darken($bg-color, 5%);
  }

  &:active {
    background-color: $bg-color;
  }

  &:disabled:hover {
    background-color: $bg-color;
  }
}

@mixin transparent($bg-color, $transparency: 0.25) {
  &-transparent {
    color: $bg-color;
    background-color: rgba($bg-color, $transparency);

    &:hover {
      background-color: rgba($bg-color, $transparency * 1.6);
    }

    &:hover:disabled {
      background-color: rgba($bg-color, $transparency);
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-icon {
  padding-left: 3px;
}

.bc-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;

  border-radius: 12px;
  outline: none;
  border: none;

  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: $color-white;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  &:hover {
    // transition: all 300ms ease;
  }

  &:disabled {
    cursor: not-allowed;
    filter: opacity(40%);
  }

  &.green {
    @include button-common($color-green-dark);
    @include transparent($color-green-dark, 0.05);

    &-transparent {
      border: 1.5px solid $color-green-dark;
    }
  }

  &.primary {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    background: #0075ff;
    border-radius: 10px;
    color: #ffffff;

    width: 100%;
    padding: 7px 42px;
    // margin-bottom: 30px;

    outline: none;
    border: none;

    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }

  &.primary:hover {
    opacity: 0.8;
  }

  &.secondary {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    background: #f0f6fe;
    border-radius: 6px;
    color: #67a8f4;

    width: 100%;
    padding: 6px 20px;
    // margin-bottom: 30px;

    outline: none;
    border: none;

    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  &.secondary:hover {
    opacity: 0.8;
  }

  &.delete {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    background: rgba(245, 108, 123, 0.15);
    border-radius: 6px;
    color: #f56c7b;

    width: 100%;
    padding: 6px 20px;
    // margin-bottom: 30px;

    outline: none;
    border: none;

    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  &.delete:hover {
    opacity: 0.8;
  }

  &.light-blue {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    background: #f0f6fe;
    border-radius: 6px;
    color: #67a8f4;

    width: 100%;
    padding: 6px 20px;
    // margin-bottom: 30px;

    outline: none;
    border: none;

    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  &.light-blue:hover {
    opacity: 0.8;
  }

  &.small {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    background: #67a8f4;
    border-radius: 10px;
    color: #ffffff;

    width: 100%;
    padding: 11px 16px;
    // margin-bottom: 30px;

    outline: none;
    border: none;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  &.small:hover {
    opacity: 0.8;
  }

  &.about {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    background: none;
    border-radius: 10px;
    color: #67a8f4;

    width: 100%;
    padding: 11px 16px;
    // margin-bottom: 30px;

    outline: none;
    border: none;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  &.about:hover {
    opacity: 0.8;
  }

  &.gray {
    color: $color-gray-dark;
    @include button-common($color-gray-light);
  }

  &.red {
    @include button-common($color-red);
    @include transparent($color-red);
  }
}
