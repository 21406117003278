.new-password {
  &-container {
    background: url('/images/subscripthion/bg.png');
    background-size: cover;
    // padding: 42px 0px 90px 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-fields {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  &-btn-back {
    display: flex;
    padding-left: 100px;
    padding-top: 1.6%;
    a {
      margin-left: 10px;
      border: none;
      background-color: none;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &-btn-wrapper {
    margin-top: 24px;
    width: 400px;
    a {
      text-decoration: none;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 50px;
    width: 500px;
    border-radius: 20px;
    margin-top: 2%;

    &__mail {
      margin-top: 50px;
      margin-bottom: 18px;
      color: #1e1e1e;
      font-weight: 700;
      font-size: 18px;
    }

    &__text {
      line-height: 20px;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      margin-bottom: 18px;

      span {
        color: #67a8f4;
      }
    }

    &__warning {
      color: #9c9c9c;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 24px;

      span {
        color: #67a8f4;
        border-bottom: 1px solid #67a8f4;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &-privacy-policy {
    padding-top: 3%;
    padding-bottom: 5%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    span {
      color: #67a8f4;
      border-bottom: 1px solid #67a8f4;
    }
  }
}
