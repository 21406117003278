.foodcost-dynamic {
  &__header {
    display: flex;
    justify-content: space-between;
    &-selects {
      display: flex;
    }
  }

  &__info {
    display: flex;
    ul {
      display: flex;
      list-style: none;
      margin-bottom: 8px;
    }
    li {
      margin-left: 20px;
      color: #9c9c9c;
      font-size: 12px;
      font-weight: 400;
      &:first-child {
        margin-left: 0px;
      }
      &:first-child::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #0a3160;
        margin-right: 4px;
      }
      &:last-child::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #67a8f4;
        margin-right: 4px;
      }
    }
  }
}
