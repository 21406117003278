.sales-progress-bar {
  &__container {
    height: 20px;
    width: 100%;
    background-color: #8aafd8;
    border-radius: 50px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  &__filler {
    height: 100%;
    border-radius: inherit;
    text-align: center;
    transition: width 0.2s ease-in-out;
  }
  &__label {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }

  &__filler-completed {
    position: relative;
    height: 100%;
    border-radius: inherit;
    text-align: center;
    transition: width 0.2s ease-in-out;
  }
  &__label-completed {
    position: relative;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }
  &__line {
    position: absolute;
    top: -1px;
    right: 37px;
    width: 1.831px;
    height: 19px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
  }

  &__info {
    display: flex;
    justify-content: space-between;

    &-title {
      color: #e3e3e3;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
    }
    &-sum {
      position: relative;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
      cursor: pointer;
    }
    &-sum_max {
      position: relative;
      padding-right: 22px;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      padding-top: 5px;
      cursor: pointer;
    }
    &_hover {
      position: absolute;
      top: -55px;
      left: -47px;
    }
  }
}
