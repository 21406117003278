.tooltip-writeOff {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  color: #fff;
  padding: 6px 10px;

  &__column {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 15px 10px;
    padding-top: 6px;
  }

  &__circle::before {
    content: '';
    position: absolute;
    top: 30.5px;
    background-color: #67a8f4;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &__circle-second::before {
    content: '';
    position: absolute;
    top: 68.5px;
    background-color: #0a3160;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  &__label {
    color: #1e1e1e;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;

    span {
      font-weight: 800;
    }
  }

  &__date {
    color: #9c9c9c;
    font-size: 10px;
    font-weight: 800;
    line-height: 120%; /* 12px */
  }
}


.recharts-tooltip-cursor {
  stroke: #67a8f4;
  stroke-width: 1px;
  stroke-dasharray: 5 5;
}
