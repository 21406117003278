.comments-select {
  &-options {
    position: relative;
    background-color: #f9f9f9;
    margin-left: 8px;
    border-radius: 6px;
    margin-right: 8px;
  }

  &-options-close {
    position: relative;
    background-color: #f9f9f9;
    margin-left: 16px;
    border-radius: 6px;
    margin-right: 16px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 14px;
    cursor: pointer;
    height: 36px;
    gap: 10px;
    // width: 175.7px;

    &-label {
      color: #2b2b2b;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-arrow {
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
    background: url('../../../../../../../../icons/arrow-select.svg');
  }

  // ../../../icons/arrow-select.svg
  &-arrow.expanded {
    transform: rotate(180deg);
  }

  &-options-list {
    z-index: 5;
    padding: 8px 14px;
    position: absolute;
    top: 39px;
    right: 0;
    width: 220px;
    max-height: 200px;
    background-color: #ffffff;
    box-shadow: 2px 6px 20px 0px rgba(85, 85, 85, 0.09);
    border-radius: 6px;
  }

  &-options-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-option {
    display: block;
    padding: 8px 0px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }

  &-optionn input[type='checkbox'] {
    margin-right: 5px;
    color: blue;
  }
}
