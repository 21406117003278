.tofu-qr {
  &__container {
    // background-image: url('../../../../../images/settings/reviewsPlatform/nine-to-sixteen-orange.jpg');
    padding: 17px 20px 20px 20px;
    width: 300px;
    background-size: cover;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    img {
      height: 53px;
      width: 53px;
      border-radius: 50%;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
    gap: 10px;
  }

  &__wrapper-first {
    display: flex;
  }

  &__wrapper-second {
    display: flex;
    justify-content: flex-end;
    margin-top: 12.5px;
    margin-bottom: 12.5px;
  }

  &__wrapper-third {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &__text {
    display: flex;
    flex-direction: column;


    &-first {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #67a8f4;
      border-radius: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 140px;
      height: 38px;
    }

    &-second {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #fff;
      border-radius: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 202px;
      height: 38px;
    }

    &-third {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #0075ff;
      border-radius: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 259px;
      height: 38px;
    }
  }
  &__code {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12.5px;
    flex-direction: column;
    background-color: #fff;
    border-radius: 38px;
    padding-bottom: 10px;

    p {
      margin-top: 20px;
      color: #1e1e1e;
      text-align: center;
      font-size: 10px;
      font-weight: 700;
      line-height: 120%; /* 12px */
      text-transform: uppercase;
    }

  }

  &.oneToOne {
    &__container {
      background-image: url('../../../../../images/settings/reviewsPlatform/nine-to-sixteen-blue.jpg');
      padding: 17px 20px 20px 20px;
      width: 300px;
      background-size: cover;
    }

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      img {
        height: 53px;
        width: 53px;
        border-radius: 50%;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;

      &-wrapper-first {
        display: flex;
      }

      &-wrapper-second {
        display: flex;
        justify-content: flex-end;
        margin-top: 12.5px;
        margin-bottom: 12.5px;
      }

      &-wrapper-third {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-first {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: #67a8f4;
        border-radius: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 140px;
      }

      &-second {
        display: flex;
        align-content: center;
        justify-content: center;
        color: #000;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: #fff;
        border-radius: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 202px;
      }

      &-third {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: #0075ff;
        border-radius: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 259px;
      }
    }
    &__code {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12.5px;
      flex-direction: column;
      background-color: #fff;
      border-radius: 25px;

      p {
        margin-top: 20px;
        color: #1e1e1e;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        line-height: 120%; /* 12px */
        text-transform: uppercase;
      }

    }
  }
}

// three to four

.threeToFour {
  padding: 17.62px 36.4px 15px 36.1px;
}

.threeToFourLogo {
  margin-bottom: 8px;
}

.threeToFourText {
}

.threeToFourWrapperFirst {
}

.threeToFourWrapperSecond {
  display: flex;
  justify-content: flex-end;
  margin-top: 9.38px;
  margin-bottom: 9.38px;
}

.threeToFourWrapperThird {
  display: flex;
  align-items: center;
  justify-content: center;
}

.threeToFourTextFirst {
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 9px;
  height: 28px;
}

.threeToFourTextSecond {
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 9px;
  height: 28px;
  align-items: center;
}

.threeToFourTextThird {
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 9px;
  height: 28px;
}
.threeToFourCode {
    border-radius: 19px;
  p {
    margin-top: 13px;
  }

  img {
    border-radius: 25px;
  }
}

// one-to-one

.oneToOne {
  padding: 8.62px 36.5px 13.3px 36.5px;
}

.oneToOneLogo {
  margin-bottom: 8px;
}

.oneToOneText {
  margin-top: 9px;
  margin-bottom: 9px;
}

.oneToOneWrapperFirst {
}

.oneToOneTextFirst {
  font-size: 7px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  height: 21px;
}

.oneToOneWrapperSecond {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 0px;
}
.oneToOneTextSecond {
  font-size: 7px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  align-items: center;
  height: 21px;
}

.oneToOneWrapperThird {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 0px;
  width: auto;
}
.oneToOneTextThird {
  font-size: 7px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  height: 21px;
}

.oneToOneCode {
  border-radius: 18px;
  p {
    margin-top: 13px;
    font-size: 6.75px;
  }


}
