.like-for-like-table {
  &-section__container {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    justify-content: center;

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &:nth-child(1) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  &-section__wrapper {
    height: 50px;
    display: grid;
    border-bottom: 1px solid #f6f6f6;
    align-items: center;
    justify-content: center;
    grid-template-columns: 29.63% 12.96% 12.96% 12.16% 9.72% 12.16% 9.72%;
    // grid-template-columns: 29.63% 12.96% 12.96% 12.96% 9.72% 12.96% 9.72%;
    // 31.4% 13% 11.5% 22.1% 22.22%
  }
  &-section__title {
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0px 16px 15px;
    cursor: pointer;
    text-align: left;

    &:first-child {
      font-size: 14px;
    }
  }

  &-section__numbers {
    height: 50px;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid #f6f6f6;
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 700;
    padding: 11px 0px 14px 15px;
    cursor: pointer;
    text-align: left;

    &:nth-child(5) {
      border-left: none;
    }
    &:nth-child(7) {
      border-left: none;
    }
  }

  &-section__main-title {
    display: flex;
    padding-left: 10px;
    position: relative;
  }

  &-section__close {
    display: none;
  }

  &-row__wrapper {
    display: grid;
    grid-template-columns: 29.63% 12.96% 12.96% 12.16% 9.72% 12.16% 9.72%;
    border-bottom: 1px solid #f6f6f6;
    align-items: center;
    justify-content: center;
  }

  &-row__main-title {
    height: 50px !important;
    color: #2b2b2b;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
    padding: 10px 0px 10px 30px;
  }

  &-row__title {
    display: flex;
    align-items: center;
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 0px 12px 15px;
    border-left: 1px solid #f6f6f6;
    height: 100%;

    &:first-child {
      border-left: none;
      padding: 0px;
    }

    &:nth-child(5) {
      border-left: none;
    }
    &:nth-child(7) {
      border-left: none;
    }
  }

  &_row-info {
    display: flex;
  }

  &-row-positive {
    width: fit-content;
    margin-top: -4px;
    padding: 5px 8px;
    border-radius: 33px;
    background: rgba(132, 217, 191, 0.2);
    color: #84d9bf;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-row-negative {
    width: fit-content;
    margin-top: -4px;
    padding: 5px 8px;
    border-radius: 33px;
    background: rgba(245, 108, 123, 0.2);
    color: #f56c7b;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-row__btn-wrapper {
    padding-left: 10px;
  }

  &-info {
    display: flex;
    align-items: center;
  }
}
