.modal-cheque {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 25px 30px 10px 30px;

    &-close {
      cursor: pointer;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;


    &__table{
      margin-bottom: 10px;
    }
  }
}
