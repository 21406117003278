.demo-header {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 18px;
  }

  &-btns {
    display: flex;
    align-items: center;
  }

  &-dots {
    margin-left: 25px;
  }
}