.cheque-hover {
  &_container {
    display: flex;
    padding: 7px 9px;
    min-width: 180px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  }
  &_text {
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &_num {
      color: #1e1e1e;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &-bold {
      color: #1e1e1e;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
