.table {
  &-section__container {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    justify-content: center;
    border-radius: 15px;
    margin-bottom: 20px;
  }

  &-section__wrapper {
    display: grid;
    border-bottom: 1px solid #f6f6f6;
    // padding: 10px 0px 10px 25px;
    align-items: center;
    justify-content: center;
  }

  &-section__title {
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 800;
    padding: 20px 0px 20px 15px;
    cursor: pointer;
    text-align: left;

    &:first-child {
      font-size: 16px;
    }
  }

  &-section__main-title {
    display: flex;
    padding-left: 10px;
  }

  &-section__close {
    display: none;
  }

  &-row_container {
  }

  &-row__wrapper {
    display: grid;
    // grid-template-columns: 33.5% 11.1% 11.1% 11.1% 11.1% 11.1% 11.1%;
    grid-template-columns: 22.77% 12.87% 12.87% 12.87% 12.87% 12.87% 12.87%;
    border-bottom: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
    // padding: 10px 0px 7px 25px;
    align-items: center;
    justify-content: center;
  }

  &-row__main-title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
    padding: 10px 0px 10px 30px;
  }

  &-row__title {
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 700;
    padding: 10px 0px 12px 15px;
  }

  &-row__btn-wrapper {
    padding-left: 10px;
  }
}
