.subscription-one-form {
  &__container {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 15px;
    width: 727px;
  }

  &__title{
    padding-left: 30px;
    margin-top: 13px;
  }

  &__advantages{
    margin-top: 18px;
    margin-bottom: 40px;
    padding-left: 30px;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      color: #2b2b2b;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-top: 8px;
    }
    span {
      margin-top: 4px;
      margin-right: 8px;
    }
  }

  &__button{
    display: flex;
    align-items: center;
    justify-content: center;

    a{
      text-decoration: none;
    }
  }
}
