.page-wrapper {
  font-family: 'Mulish';
  height: 100vh;

  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;

    &.open {
      display: grid;
      grid-template-columns: 7% 93%;
      transition: all 0.3s ease;
      @media (max-width: 768px) {
        display: none;
      }
    }

    &.close {
      display: grid;
      transition: all 0.3s ease;
      grid-template-columns: 14% 86%;
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }

  &__content-section {
    width: 100%;
    background: #f6f6f6;

    .reviewsPage {
      overflow: hidden;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 30px;

      &_constructor {
        height: 100vh;
        overflow: hidden;
      }
    }

    &_body {
      // height: calc(100vh - 82px);
      // overflow: hidden;
      margin-left: 40px;
      margin-right: 40px;

      &_constructor {
        height: 100vh;
        overflow: hidden;
      }
    }

    &.open {
      // padding-left: 200px;
      // transition: all 300ms cubic-bezier(0.29, 0.01, 0, 1.04);

      @media (max-width: 768px) {
        display: none;
      }
    }

    &.close {
      // padding-left: 100px;
      // transition: all 300ms cubic-bezier(0.29, 0.01, 0, 1.04);

      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }
}
