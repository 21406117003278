.checque-card {
  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    padding-bottom: 10px;
    width: 100%;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.05);
    }
    margin-top: 10px;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-info {
      margin-bottom: 14px;
    }

    &-sum {
      text-align: center;
      color: #2b2b2b;
      font-size: 24px;
      font-weight: 800;
    }

    &-title {
      color: #9c9c9c;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
    }

    &-result {
      position: relative;
      border-radius: 20px;
      padding: 4px 8px 4px 6px;
      margin-bottom: 10px;
      font-size: 10px;
      color: #f56c7b;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
      &_hover {
        position: absolute;
        top: 29px;
        left: -46px;
      }
      &__red {
        color: #f56c7b;
      }
      &__green {
        color: #84d9bf;
      }
      &__gray {
        color: #2b2b2b;
      }
    }
  }

  &__container-hovered {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    padding-bottom: 10px;
  }
}

.checque-card__container-hovered {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-top: 14px;
  padding-bottom: 10px;
  border-radius: 10px;
  background: linear-gradient(251deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%),
    #67a8f4;
}

.checque-card__top-hovered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checque-card__top-info-hovered {
  position: relative;
  margin-bottom: 14px;
}

.checque-card__top-sum-hovered {
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: 800;
}

.checque-card__top-title-hovered {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.checque-card__top-result-hovered {
  border-radius: 20px;
  padding: 4px 8px 4px 6px;
  margin-bottom: 10px;
  font-size: 10px;
  color: #f56c7b;
}

.red {
  background: linear-gradient(0deg, rgba(245, 108, 123, 0.2) 0%, rgba(245, 108, 123, 0.2) 100%),
    #fff;
}

.green {
  background: linear-gradient(0deg, rgba(132, 217, 191, 0.2), rgba(132, 217, 191, 0.2)), #ffffff;
}

.gray {
  background: linear-gradient(0deg, rgba(43, 43, 43, 0.05) 0%, rgba(43, 43, 43, 0.05) 100%), #fff;
}
