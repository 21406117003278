.access-staff {
  &__container {
    margin-top: 28px;
  }

  &__columns {
    display: grid;
    grid-template-columns: 40% 12% 12% 12% 12% 12%;
    padding-left: 25px;
    margin-bottom: 9px;

    &-title {
      color: #2b2b2b;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__table {
    background-color: #fff;
    border-radius: 15px;
    &-columns {
      &-department {
        padding: 14px 0px 8px 25px;
        color: #2b2b2b;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
      }
      &-info {
        &-container {
          display: grid;
          grid-template-columns: 40.8% 12% 12% 12% 12% 12%;
          border-top: 1px solid #f6f6f6;
          border-bottom: 1px solid #f6f6f6;
          padding: 5px 0px 5px 25px;

          &:hover {
            background-color: #f9f9f9;
          }
        }

        &-person {
          display: flex;
        }

        &-staff {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 5px;

          &-name {
            color: #2b2b2b;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
          }

          &-role {
            color: #9c9c9c;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
          }
        }

        &-img {
          border-radius: 50%;
          object-fit: cover;

          &-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &-checkbox {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        &-settings {
          display: flex;
          align-items: center;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
