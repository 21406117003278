.star-selector {
  &__wrapper {
    margin-bottom: 40px;
  }
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(165deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%),
      rgba(103, 168, 244, 0.25);
    padding: 8px 20px;
    width: 316px;
    -webkit-tap-highlight-color: transparent; /* For iOS Safari */
    -moz-tap-highlight-color: transparent; /* For older versions of Firefox */
    -ms-tap-highlight-color: transparent; /* For Internet Explorer/Edge */
    tap-highlight-color: transparent; /* For modern browsers */
  }

  &__star {
    padding-right: 7px;
    touch-action: manipulation;
    &:last-child {
      padding-right: 0px;
    }
  }
}
