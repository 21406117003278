.company-form {
  &__container {
    background: #ffffff;
    box-shadow: 0px 4px 90px rgba(1, 29, 63, 0.4);
    border-radius: 20px;
    padding: 40px 50px 50px 50px;
    width: 916px;
  }

  &__upload {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2b2b;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 16px;
  }
}
