.parametr-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    &-title {
      color: #2b2b2b;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &-icon {
      cursor: pointer;
    }
  }

  &__group{
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 12px;
    margin-bottom: 22px;
  }

  &__list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    height: 25px
  }

  &__desc {
    color: #9c9c9c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 18px;
    margin-top: 16px;
  }

  &__list {
    display: flex;
    gap: 10px;
    // padding-bottom: 14px;
  }

  &__item {
    color: #2b2b2b;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
