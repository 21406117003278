.popover {
  &__window {
    padding: 13px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #1e1e1e;
    font-weight: 400;
    font-size: 14px;
  }

  &__text {
    margin-left: 10px;
    font-weight: 400;
    font-size: 14px;
  }
}

.tofy {
  &__window {
    display: flex;
    flex-direction: column;
    width: 196px;
    padding: 10px 14px;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
  }

  &__first-row {
    display: flex;
    justify-content: space-between;
  }

  &__second-row {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.custom-link {
  text-decoration: none;
  color: inherit;
}
