.foodcost {
  &__container {
    display: flex;
    flex-direction: row;
  }

  &__first-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__second-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__right-side {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-up {
      display: flex;
      gap: 20px;
    }
  }

  &__dynamic {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
  }

  &__write-off {
    width: 100%;
    height: 41%;
    background: #ffffff;
    border-radius: 15px;
  }

  &__top-ten-widget {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    position: relative;
  }

  &__average-rating {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    position: relative;
  }

  &__dynamic-cloud-container{
    margin: 16px 0px 0px 20px;
  }

  &__cloud-container {
    margin: 16px 20px 20px 20px;

    &-write-off {
      margin: 16px 20px 20px 0px;
    }

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    &-title-write-off {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-info-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-info-top {
      display: flex;
    }

    &-hover {
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  &__table {
    &-header {
      margin-top: 26px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content: space-between;
    }
    &-wrapper {
      margin: 16px 0px 20px 0px;
    }
  }
}
