.banner-statistics {
  &__container {
    border-right: 1px solid rgba(246, 246, 246, 0.1);
    padding-right: 35px;
    padding-left: 35px;

    &:first-child {
      padding-left: 0px;
    }
    &:last-child {
      border-right: none;
      padding-right: 0px;
    }
  }
  &__title {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }

  &__sum {
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    line-height: normal;
    margin-top: 4px;

    span {
      font-weight: 400;
    }
  }

  &__percent {
    color: #e3e3e3;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    span {
      font-weight: 700;
    }
  }
}
