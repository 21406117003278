.like-for-like {
  &__selects {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    &-left{
      display: flex;
      gap: 15px;
    }
  }

  &__container {
    margin-bottom: 40px;
  }
}
