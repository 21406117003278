.abc-analysis {
  &__container {
    display: flex;
    align-items: center;
  }

  &__info {
    padding-top: 10px;
  }

  &__title {
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
  }
}
