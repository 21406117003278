.switcher {
    label{
        margin-right: 0px;
    }
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-text {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 400;

    &-title {
      color: #2b2b2b;
      font-size: 16px;
      font-weight: 400;
    }

    &-desc {
      color: #9c9c9c;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
