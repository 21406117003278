.week-report {
  &__container {
    margin-top: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px 30px;
  }

  &__top {
    &-title {
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-weeks {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;

      &-title {
        color: #9c9c9c;
        font-size: 16px;
        font-weight: 400;
      }
      &-price {
        color: #2b2b2b;
        font-size: 16px;
        font-weight: 700;

        span {
          color: #2b2b2b;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  &__bottom {
    margin-top: 20px;
    &-title {
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-date {
      color: #9c9c9c;
      font-size: 16px;
      font-weight: 400;
    }

    &-price {
      color: #2b2b2b;
      font-size: 16px;
      font-weight: 700;

      span {
        color: #2b2b2b;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
