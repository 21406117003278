.restaurant-comparison {
  &__container {
  }

  &__selects {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    // margin-bottom: 75px;

    &-left{
      display: flex;
    }
  }

  &__table {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    overflow-y:hidden;
    margin-top: 15px;
  }
}
