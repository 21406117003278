.hover-percent {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
    padding: 7px 9px !important;
    gap: 4px;
    font-size: 12px;
    height: 70px;
  }
  &__top-day-info {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1e1e1e;
  }
  &__bold {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #1e1e1e;
  }
  &__bottom {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #9c9c9c;
  }
  &__text {
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.revenue {
  height: auto;
}
