.subscription {
  &-container {
    background: url('/images/subscripthion/bg.png');
    min-height: 100vh;
    padding: 22px 140px 20px 140px;
  }

  &-header{
    display: grid;
    grid-template-columns: 30% 40% 30%;
}

  &-btn-back {
    display: flex;
    a {
      margin-left: 10px;
      border: none;
      background-color: none;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
    }
  }

  &-start {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 900;
    font-size: 28px;
  }

  &-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f6f6f6;
    font-weight: 400;
    font-size: 16px;
  }
}
