.comment {
  margin-top: 40px;

 &__stars {
    display: flex;
    padding-top: 6px;
    padding-bottom: 13px;
  }
  
 &__author {
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
  }
  
 &__date {
    padding-left: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #c4c4c2;
    display: flex;
    align-items: center;
  }
  
 &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #1e1e1e;
  }
}
