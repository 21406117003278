.sub-row {
  &__container {
  }

  &__wrapper {
    display: grid;
    // grid-template-columns: 33.5% 11.1% 11.1% 11.1% 11.1% 11.1% 11.1%;
    grid-template-columns: 22.77% 12.87% 12.87% 12.87% 12.87% 12.87% 12.87%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f6f6f6;
  }

  &__main-title {
    padding: 0px 0px 0px 40px;
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 400;
    margin-left: 20px;
    display: flex;
    align-items: center;
    // margin-top: 12px;
    // margin-bottom: 13px;
  }

  &__abc {
    position: relative;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 11px;
    gap: 1px;
  }

  &__title-wrapper {
  }

  &__title {
    border-left: 1px solid #f6f6f6;
    border-right: none;
    padding: 12px 0px 13px 15px;
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 400;
    &.category-A {
      border-radius: 7px;
      background: rgba(132, 217, 191, 0.2);
      padding: 10px 0px 10px 15px;
      margin-right: 2px;
    }
    &.category-B {
      border-radius: 7px;
      background: rgba(255, 199, 0, 0.2);
      padding: 10px 0px 10px 15px;
      margin-right: 2px;
    }
    &.category-C {
      border-radius: 7px;
      background: rgba(245, 108, 123, 0.2);
      padding: 10px 0px 10px 15px;
      margin-right: 2px;
    }

    &-abc {
      display: flex;
      justify-content: space-between;
      padding-right: 13px;
    }
  }
}

.writeOff {
  display: grid;
  grid-template-columns: 43.15% 14.27% 14.27% 14.27% 14.27%;
  align-items: center;
  justify-content: center;
}

.writeOffSecond {
  display: grid;
  grid-template-columns: 43% 19% 19% 19%;
  align-items: center;
  justify-content: center;
}

.revenue{
  position: relative;
  cursor: pointer;
}