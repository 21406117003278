@import './src/styles/variables';

.fields {
  position: relative;
  width: 100%;

  label {
    display: flex;
    position: relative;
  }

  .placeholder {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: #9c9c9c;
  }

  &__input-icon {
    position: absolute;
    right: 12px;
    top: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__input-icon-wrapper {
    position: relative;
    width: fit-content;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;

    color: #1E1E1E;
  }

  &__input {
    color: #2B2B2B;

    outline: 0;
    outline-offset: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 15px;
    font-size: 16px;

    width: 100%;
    height: 52px;
    background: #f9f9f9;
    transition: border 0.25s;
    border: none;
    border-radius: 10px;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #848b91;
    }

    &:hover {
      border-color: #848b91;
      transition: border-color 0.25s;
    }

    &:active {
      outline: 0;
      outline-offset: 0;
      border-color: #848b91;
    }

    &:focus {
      outline: 0;
      outline-offset: 0;
      border-color: #848b91;
    }
  }

  &__filled-input {
    outline: 0;
    outline-offset: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 15px;
    font-size: 16px;

    width: 100%;
    height: 52px;
    background: #f9f9f9;
    transition: border 0.25s;
    border: none;
    border-radius: 10px;

    &:hover {
      outline: 0;
      outline-offset: 0;
      border-color: #848b91;
      transition: border-color 0.25s;
    }

    &:active {
      outline: 0;
      outline-offset: 0;
      border-color: #848b91;
    }

    &:focus {
      outline: 0;
      outline-offset: 0;
      border-color: #848b91;
    }
  }

  &__empty-input {
    caret-color: $color-blue;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 15px;
    font-size: 16px;

    width: 100%;
    height: 52px;
    border: 1px solid #f54e19;
    transition: border 0.25s;
    color: #f44336;

    border-radius: 20px;

    &:hover {
      border-color: #f54e19;
      transition: border-color 0.25s;
    }

    &:active {
      outline: 0;
      outline-offset: 0;
      border-color: #f54e19;
    }

    &:focus {
      outline: 0;
      outline-offset: 0;
      border-color: #f54e19;
    }
  }

  &__error-input {
    caret-color: $color-blue;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 15px;
    font-size: 16px;

    width: 100%;
    height: 52px;
    background: #ffe6f2 !important;
    border: 1px solid solid #f54e19;
    transition: border 0.25s;
    color: #f44336;

    border-radius: 20px;

    &:hover {
      border-color: #f54e19;
      transition: border-color 0.25s;
    }

    &:active {
      outline: 0;
      outline-offset: 0;
      border-color: #f54e19;
    }

    &:focus {
      outline: 0;
      outline-offset: 0;
      border-color: #f54e19;
    }
  }

  &__error-value {
    margin-top: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #f54e19;
  }
}

.input-span {
  position: relative;
}


.formError{
  border: 1px solid #F56C7B;
  margin-bottom: 0px;
  background: #F9F9F9 ;
}