.form {
  display: grid;
  grid-template-columns: 1fr;

  &-text {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 7px;
    font-weight: 700;
    font-size: 16px;
  }

  &__error {
    text-align: center;
    color: rgb(232, 19, 19);
    margin-bottom: 10px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    &:hover {
      opacity: 0.5;
    }
  }

  &__text {
    margin-bottom: 15px;
    color: #1e1e1e;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__textarea {
    font-size: 12px;
    padding: 8px 0px 30px 10px;
    border-radius: 10px;
    border: 0.6px solid #c4c4c2;

    transition: border-color 0.2s ease-in-out;
    width: 316px !important;
    margin-bottom: 15px;
  }

  &__input {
    font-size: 12px;
    padding: 15px 0px 15px 10px;
    border-radius: 10px;
    border: 0.6px solid #c4c4c2;
    touch-action: manipulation;

    transition: border-color 0.2s ease-in-out;
    width: 316px;
    margin-bottom: 15px;
  }

  &__input::placeholder {
    font-size: 14px;

    font-weight: 400;
    color: #707070;
  }

  &__textarea::placeholder {
    color: #707070;
    font-size: 14px;

    font-weight: 400;
  }

  &__privacy-policy {
    color: #c4c4c2;
    font-size: 10px;
    font-weight: 500;
    line-height: 120%;
    padding-top: 15px;
    max-width: 260px;
    // margin-left: 5px;

    a {
      color: #c4c4c2;
      font-size: 10px;
      font-weight: 500;
      text-decoration: none;
      border-bottom: 0.5px solid #c4c4c2;
    }
  }

  &-delete-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    gap: 5px;
    p {
      color: #67a8f4;
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
    }

    span {
      color: #c4c4c2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__input-error {
    color: #f56c7b;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    margin-bottom: 5px;
  }
}

.thansk-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 7px;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 40px;
}

.validation-error {
  color: #f56c7b;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 0px;
}

.formError {
  border: 1px solid #f56c7b;
  margin-bottom: 0px;
}
