.staff-settings__cards{
    &-deparment{
        margin-top: 20px;
        margin-bottom: 16px;
    }
    &-section{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
}