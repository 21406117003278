.loading-screen-qr {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
  }

  &__text {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    margin-top: 5px;
  }
}
