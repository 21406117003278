.user-form-comment {
  padding: 9px 10px 13px 10px;
  background: linear-gradient(
      112.88deg,
      rgba(255, 255, 255, 0.5) 29.82%,
      rgba(255, 255, 255, 0) 78.41%
    ),
    rgba(103, 168, 244, 0.25);
  border-radius: 10px;
  width: 316px;
  animation: fade-in 0.5s;

  &__stars {
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 13px;
  }

  &__author {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 12px;

    &-btn {
      display: flex;
      gap: 15px;
      button {
        background: none;
        border: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent; /* For iOS Safari */
        -moz-tap-highlight-color: transparent; /* For older versions of Firefox */
        -ms-tap-highlight-color: transparent; /* For Internet Explorer/Edge */
        tap-highlight-color: transparent; /* For modern browsers */

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &__dots {
    position: absolute;
    right: 1px;
    top: -14px;
  }

  &__date {
    padding-left: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #707070;
  }

  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #1e1e1e;
    max-width: 200px;
    display: block;
  }

  &__review {
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 500;
    border: none;
    width: -webkit-fill-available;
  }

  &__review-edit {
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 500;
    border-radius: 5px;
    border: 0.5px solid #67a8f4;
    padding: 10px;
    width: -webkit-fill-available;
    background: none;
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 13px;

    &-btn-cancel {
      margin-right: 15px;
      background: #ffffff;
      color: #2b2b2b;
      padding: 4px 12px;
      border-radius: 6px;
    }

    &-btn-save {
      background: #67a8f4;
      color: #fff;
      padding: 4px 12px;
      border-radius: 6px;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
