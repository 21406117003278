.custom-select {
  display: flex;
  width: 110px;
  padding: 4px 6px 4px 12px;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 6px;
  background: #f9f9f9;
  cursor: pointer;
}

.select-selected {
  display: flex;
  gap: 6px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.arrow-close {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

.arrow-open {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.select-options {
  position: absolute;
  top: 100%;
  left: -59px;
  z-index: 999;
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-top: none;
}

.custom-select.open .select-options {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 6px 20px 0px rgba(85, 85, 85, 0.09);
}

.select-options li {
  display: flex;
  width: 110px;
  height: 34px;
  padding: 8px 14px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.select-options_off {
  display: none;
}
.select-options_active {
  display: flex;
}

.select-option__text {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #2b2b2b;
  text-align: center;
}

.select-options_active__text {
  color: #3498db;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.select-options_off__text {
  color: #1e1e1e;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.select-options_off__text:hover {
  color: #3498db;
}
