.range-wrapper {
  margin-bottom: 40px;
}

.navigation-wrapper {
  position: relative;
  width: 316px;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent; /* For iOS Safari */
  -moz-tap-highlight-color: transparent; /* For older versions of Firefox */
  -ms-tap-highlight-color: transparent; /* For Internet Explorer/Edge */
  tap-highlight-color: transparent; /* For modern browsers */
  background: linear-gradient(
      112.88deg,
      rgba(255, 255, 255, 0.5) 29.82%,
      rgba(255, 255, 255, 0) 78.41%
    ),
    rgba(103, 168, 244, 0.25);

  border-radius: 10px;
}

.number-slide {
  padding: 8px 20px 0px 20px;

  &:first-child {
    padding: 15px 20px 0px 20px;
  }

  &:last-child {
    padding: 8px 20px 2px 20px;
  }
}
.slider-line {
  height: 1px;
  width: 276px;
  background-color: #67a8f4;
  opacity: 0.2;
  margin-bottom: 18px;
}

.slider-line.first {
  display: none;
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  margin-top: 13px;
  padding-right: 0px;
  padding-left: 0px;
  width: 276px;
}

.emoji {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.emoji.animate {
  opacity: 1;
}

.question-number {
  padding: 0px 0px 11px 0px;
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 700;
}

.question-description {
  height: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #1e1e1e;
  font-weight: 500;
}

.numbers-wrapper {
  position: relative;

  &__first-number {
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    color: #67a8f4;
    top: -22px;
    left: 0px;
  }

  &__second-number {
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    color: #67a8f4;
    left: 265px;
    top: -22px;
  }
}

.slider-arrows-wrapper {
  position: relative;
}

.sliderError {
  border: 1px solid #f56c7b;
  border-radius: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 15px;
}

.slider-text-error {
  color: #f56c7b;
  font-weight: 700;
  font-size: 12px;
}
