.tooltip {
  &-container {
    display: flex;
    flex-direction: column;
    border-radius: 0.25em;
    background: #ffffff;
    box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    padding: 6px;
  }

  &-date {
    color: #9c9c9c;
    font-weight: 800;
    font-size: 10px;
    margin-left: 1px;
    margin-bottom: 7px;
  }

  .recharts-tooltip-cursor {
    stroke: black;
    stroke-width: 0.5px;
    stroke-dasharray: none;
  }

  &__label {
    color: #84d9bf;

    padding-right: 4px;
  }
  &-list {
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 15px;
      line-height: 1.5;
      padding-right: 5px;
      color: #9c9c9c;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      span {
        color: #1e1e1e;
        font-weight: 800;
        font-size: 10px;
      }
    }

    li::before {
      content: '';
      position: absolute;
      top: 1.5px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    li:nth-child(1)::before {
      background-color: #84d9bf;
    }

    li:nth-child(2)::before {
      background-color: #f56c7b;
    }

    li:nth-child(3)::before {
      background-color: #e3e3e3;
    }
  }
}
