.like-for-like-period-select {
  &__container {
    position: relative;
    display: flex;
    border-radius: 6px;
    background: #fff;
    padding: 6px 14px;
    width: 114px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__line {
    border: 1px solid #f6f6f6;
    margin-left: 25px;
    margin-right: 5px;
  }

  &__wrapper {
    display: flex;
    background-color: #fff;
    position: absolute;
    top: 150px;
    border-radius: 6px;
    box-shadow: 2px 6px 20px 0px rgba(85, 85, 85, 0.09);
    z-index: 200;
    padding: 10px 20px 20px 0px;
  }

  &__title {
    color: #2b2b2b;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
