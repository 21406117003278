@import './variables.scss';

@mixin middleFont {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
}

@mixin lightFont {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
}

@mixin shadow {
    box-shadow: 0 0 10px $color-gray-light;
}

@mixin modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $color-white;
    border-radius: 10px;
    width: 100%;
    border: 1px solid $color-silver;

}

@mixin colorGradient($text-gradient: $gradient) {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -o-linear-gradient($gradient);
    background-image: $text-gradient;
  }



@mixin inputTemplate {
    border: 1px solid $color-gray-light;
    transition: all 100ms ease;
    color: $color-black;

    &:not(.disabled, :focus, .focused):hover {
        border: 1.5px solid rgba($color-blue, 0.5);
    }

    &:not(input[type="checkbox"]):focus {
        border: 1.5px solid $color-blue;
    }

    &:checked {
        border: none;
        background-color: #67A8F4;
        background-image: url("../icons/Vector\ 7.svg");
    }

    &.focused {
        border: 1.5px solid $color-blue;

        &-error {
            background-color: rgba($color-red, 0.15);
            border: 1.5px solid red;
            color: $color-red;
        }
    }

    &.disabled {
        cursor: not-allowed;
        background: rgba($color-gray-light, 0.1);
    }

    &.input-error {
        color: $color-black;
        border-color: $color-red;
    }
}