.settings-banner {
  &__container {
    background-image: url('../../../images/settings/subscription/banner.jpg');
    background-size: cover;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    padding: 22px 12px 12px 22px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__left {
    display: flex;
    align-items: center;

    &-text {
      padding-left: 18px;

      &__title {
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 8px;
      }

      &__desc {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        max-width: 450px;
        line-height: normal;
      }
    }
  }

  &__right {
    display: flex;
    align-items: flex-end;
    &-btn {
      background: #fff;
      border-radius: 6px;
      color: #67A8F4;
      text-align: center;
      font-size: 12px;
      font-weight: 800;

      &:hover{
        opacity: 0.8;
      }
    }
  }
}
