.modal-about {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 31px 30px 13px 30px;

    &-left {
      display: flex;
      gap: 10px;
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;

      &-arrow {
        cursor: pointer;
      }
    }

    &-right {
      cursor: pointer;
    }
  }

  &__info {
    background-color: #f9f9f9;
    padding: 20px 30px 20px 30px;

    &-text {
      color: #2b2b2b;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #2b2b2b;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &-top {
      display: flex;
      justify-content: space-between;

      &-data {
        display: flex;
        gap: 16px;
      }
    }

    &-bottom {
      margin-top: 10px;
      display: flex;
      gap: 16px;
    }
  }
}
