.switchers {
  &-container {
    background-color: #fff;
    padding: 16px 20px;
    margin-top: 20px;
    border-radius: 15px;
  }

  &-title {
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 50px;
  }
}
