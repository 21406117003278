.steps-company {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    // padding-bottom: 50px;
  }

  &-text {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 3px 5px;
    border-radius: 30px;
    width: 24px;
    height: 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-active-wrapper {
    background-color: rgba(103, 168, 244, 0.1);
    border-radius: 30px;
  }

  &-text-active {
    background-color: #67a8f4;
    padding: 3px 5px;
    border-radius: 30px;
    width: 28px;
    height: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
  }
  &-line {
    width: 82px;
    height: 4px;
    opacity: 0.1;
    background-color: #ffffff;
  }

  &-active-line{
    width: 82px;
    height: 4px;
    opacity: 0.4;
    background-color: #67a8f4;
  }
}
