.login {
  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-container {
    display: flex;
    align-items: center;
    // margin-top: 2%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // height: 680px;
  }

  &__fields {
    display: grid;
    gap: 16px;
    margin-top: 39px;
  }

  &__text-block {
    margin-top: 86px;
    width: 400px;

    &__main {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 800;
      font-size: 27px;
      line-height: 120%;
      text-align: center;
      color: #1e1e1e;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    &-or {
      font-weight: 500;
      font-size: 15.5px;
      line-height: 19px;
      display: flex;
      justify-content: center;
      color: #000000;
    }

    &-underline {
      a {
        text-decoration: none;
        border-bottom: 1px solid #67a8f4;
        color: #67a8f4;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }

  &__switch-mode {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    span {
      margin-right: 4px;
    }
  }

  &__btn {
    margin-top: 21px;
  }

  &__privacy-policy {
    margin-top: 127px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #c4c4c2;

    &-underline {
      cursor: pointer;
      color: #67a8f4;
      font-weight: 600;
      border-bottom: 1px solid #67a8f4;
    }
  }

  &__forget-password {
    margin-top: 4px;
    display: flex;
    justify-content: flex-end;
    a {
      color: #67a8f4;
      font-size: 14px;
      text-align: end;
      font-weight: 600;
      text-decoration: none;
      border-bottom: 1px solid #67a8f4;
      &:hover {
        opacity: 0.8;
      }
    }
  }

}

.custom-toast {
  background-color: #FFF;
  color: #101010;
  border-radius: 10px;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 400;
  width: 332px;
  margin-right: 60px;
}

.custom-toast-body {
  display: flex;
  align-items: center;
}

.custom-toast-body::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FFF;
  margin-right: 8px;
}

.custom-toast-body.success::before {
  background-color: #FFF;
}
