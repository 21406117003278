.two-cards {
  &__wrapper {
    display: flex;
    padding: 10px 25px;
    flex-direction: column;
    flex: 1 0 0;
    border-radius: 15px;
    background: #fff;
    gap: 5px;
  }
  &__title {
    color: #1e1e1e;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__middle {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  &__middle-percent {
    color: #1e1e1e;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    &__hover{
      position: absolute;
      top: -78px;
      right: 0;
    }

    &__diff {
      display: flex;
      padding: 4px 8px 4px 6px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 20px;
      gap: 10px;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
    }
  }
  &__sum {
    color: var(--Option-Secondary-text, #9c9c9c);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &__bold {
      font-weight: 700;
    }
  }
}
.red {
  color: #f56c7b;
  background: linear-gradient(0deg, rgba(245, 108, 123, 0.2) 0%, rgba(245, 108, 123, 0.2) 100%),
    #fff;
}
.green {
  color: #84d9bf;
  background: linear-gradient(0deg, rgba(132, 217, 191, 0.2) 0%, rgba(132, 217, 191, 0.2) 100%),
    #fff;
}
