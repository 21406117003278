.banner-sales {
  &__conatiner {
    position: relative;
    background: linear-gradient(100.35deg, #0a3160 0%, #1969c8 100%);
    border-radius: 15px;
    padding: 14px 14px 14px 21px;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
  }
  &__left-side {
    width: 25%;
    &-top {
      display: flex;
    }
    &-title {
      color: #ffffff;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      padding-bottom: 10px;
      margin-top: 14px;
    }
    &-percent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left {
        color: #ffffff;
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
      }
      &-right {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #84d9bf;
        border-radius: 20px;
        padding: 4px 8px;
        background: linear-gradient(0deg, rgba(132, 217, 191, 0.2), rgba(132, 217, 191, 0.2)),
          #ffffff;
        cursor: pointer;
        &_hover {
          position: absolute;
          top: -6px;
          left: 195px;
        }
      }
    }
    &-sum {
      position: relative;
      padding-top: 4px;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      span {
        color: #e3e3e3;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
    }
    &__hover {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    padding: 9px 0px 7px 21px;
    margin-left: 18px;
    width: 75%;
  }
  &__right-side-title {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 5px;
  }

  &__right-side-list {
    display: flex;
  }
}
