.user {
  &-container {
    background: url('/images/subscripthion/bg.png');
    min-height: 100vh;
    padding: 0px 140px 0px 140px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-header {
    display: flex;
    justify-content: center;
    height: 10%;
    padding-top: 2%;
  }

  &-btn-back {
    margin-top: 5px;
    display: flex;
    position: absolute;
    left: 100px;
    a {
      margin-left: 10px;
      border: none;
      background-color: none;
      color: #e3e3e3;
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }

  &-form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
  }

  &-start {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 900;
    font-size: 28px;
  }

  &-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f6f6f6;
    font-weight: 400;
    font-size: 16px;
  }

  &-verification-privacy-policy {
    padding-bottom: 2%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    span {
      color: #67a8f4;
      border-bottom: 1px solid #67a8f4;
    }

  }
}
