.paramentrs-table {
  &__container {
    margin-top: 44px;
  }
  &__header {
    display: flex;
    align-items: center;
    position: relative;
    color: #9c9c9c;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 13px;
    margin-bottom: 2px;
    gap: 5px;
    bottom: 5px;

    &-icon {
      margin-top: 3px;
      cursor: pointer;
    }

    &-hover {
      width: 151px;
      bottom: 23px;
      right: -65px;
      position: absolute;
      padding: 7px 9px;
      border-radius: 6px;
      background: #fff;
      box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
      color: #1e1e1e;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__wrapper {
    background: #fff;
    width: 320px;
    border-radius: 15px;
  }

  &__icon-wrapper {
    display: flex;
  }

  &__icon {
    cursor: pointer;
  }

  &__item {
    height: 50px;
    color: #1e1e1e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #f6f6f6;
    padding: 16px 25px;
    position: relative;
  }
}
