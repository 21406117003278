// @import "@styles/mixins.scss";
// @import "@styles/variables.scss";
@import "../../../styles//mixins.scss";
@import "../../../styles//variables.scss";

.common-custom-checkbox {
  appearance: none;
  width: 19px;
  min-width: 19px;
  height: 19px;
  min-height: 19px;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $color-white;
  @include inputTemplate;
  border: 2px solid #C4C4C2;

  &:hover {
    box-shadow: 0 0 0 0.2rem rgba($color-blue, 0.20);
  }
  &:disabled{
    background-color: #C4C4C2;
  }
}
