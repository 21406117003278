.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  /* width: 50%; */

  text-align: left;
}
/* .geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
} */
.geosuggest__input-wrapper {
  display: flex;
  justify-content: center;
}
/* .geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
} */
.geosuggest__suggests {

margin-top: 10px;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
   * A geosuggest item
   */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
