.text {
  color: #9c9c9c;
  font-weight: 400;
  font-size: 12px;
}

.recharts-layer.recharts-pie,
.recharts-sector:focus {
  outline: none !important;
  outline-color: none;
  outline-style: none;
  outline-width: none;
}
