.tofu-modal {
  &__top {
    display: flex;
    justify-content: space-between;

    &-icon{
        cursor: pointer;
    }

    p {
      color: #2b2b2b;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__frame {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn{
    margin-top: 16px;
  }
}

/* Target the input element inside MuiOutlinedInput component */
.MuiOutlinedInput-input {
  border: 5px solid red !important;
}

.MuiInputLabel-root {
  padding-left: 13px !important;
  color: #9c9c9c !important;
  padding-bottom: 51px !important;
  top: -4px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Mulish' !important;
}

.MuiIconButton-root {
  margin-right: 2px !important;
}
.MuiInputBase-root.MuiInput-root::before {
  border-bottom: none !important;
}

.qwef {
  background-color: #f9f9f9;
  height: 52px;
  margin-top: 19px !important;
  border-radius: 10px;
}

.MuiOutlinedInput-root {
  border: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 10px solid red !important;
}
