.staff-card {
  &__container {
    border-radius: 10px;
    background: #f9f9f9;
    padding: 8px 10px 13px 12px;
    width: 138px;
    // transition: all 0.3s ease;
    // &:hover {
    //   transform: scale(1.05);
    // }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 8px;

    &-icon {
      img {
        position: absolute;
        left: -12px;
        top: -28px;
        border: 3px solid #fff;
        border-radius: 500px;
      }
    }

    &-rating {
      color: #ffc700;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
    }
  }

  &__name {
    color: #2b2b2b;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    max-width: 70px;
  }

  &__info {
    &-title {
      margin-top: 4px;
      font-size: 10px;
      font-weight: 400;
      color: #9c9c9c;
    }

    &-desc {
      color: #2b2b2b;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
    }
  }
}

.is-close-side-bar {
  width: 125px;
}

.is-open-side-bar {
  width: 137px;
}

.without-top {
  margin-top: 20px;
}
