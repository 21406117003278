.modal-access-reports {
  &__container {
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    &-title {
      color: #2b2b2b;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
    }

    &-icon {
      cursor: pointer;
    }
  }

  &__user {
    display: flex;

    &-avatar {
      border-radius: 50%;
      object-fit: cover;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      margin-bottom: 16px;
      margin-top: 16px;
      &-name {
        color: #2b2b2b;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }

      &-role {
        color: #9c9c9c;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__reports {
    margin-bottom: 26px;
    &-title {
      color: #2b2b2b;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 16px;
    }

    &-list {
      display: grid;
      grid-template-columns: 42% 58%;
      gap: 16px;

      &-container {
        display: flex;
        gap: 10px;
      }
    }
  }
}
