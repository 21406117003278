.registration-left {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  
    &__left-top {
      display: flex;
      margin-right: 42%;
      margin-top: 22px;
    }
  
    &__left-bottom {
      display: flex;
      margin-right: 17%;
    }
  
    &__center {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
      font-size: 28px;
      font-weight: 900;
      margin-bottom: 31px;
      margin-top: 64px;
    }
  
    &__right-absolute {
      display: flex;
      margin-left: 125%;
      position: absolute;
      top: 150px;
    }
  
    &__right-top {
      display: flex;
      margin-left: 81%;
    }
  
    &__right-bottom {
      display: flex;
      margin-left: 70%;
      margin-top: 29px;
    }
  }
  