.info-container{
    position: relative;
    .info-container-popover-menthion {
        position: absolute;
        z-index: 100;
        padding: 15px;
        top: 30px;
        right: -135px;
        color: #1E1E1E;
        background-color: #FFFFFF;
        border: none;
        border-radius: 15px;
        box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.09);
        width: 300px;
        p{
            font-size: 12px;
        }
      }
      .info-icon{
        margin-top: 5px;
        width: 22px;
        height: 30px;
      }

      .info-icon  {
        fill: #E3E3E3;
        transition: fill 0.3s ease;
      }
      
   
      .info-icon:hover {
        fill: #67A8F4;
      }

    
    .info-text{
        display: flex;
        flex-direction: column;
        &-formula{
            color: #67A8F4;
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 800;
        }
        &-top{
            margin-bottom: 10px;
        }
        &-example{
            color: #9C9C9C;
            margin-top: 10px;
            max-width: 280px;
        }
        &-list{
            margin-left: 10px;
            margin-bottom: 20px;
            li{
                font-size: 12px;
                margin-left: 8px;
            }
        }
        &-about{
            margin-bottom: 10px;
        }
    }
    .info-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    
        li {
          position: relative;
          margin-bottom: 5px;
          padding-left: 15px;
          line-height: 1.5;
          padding-right: 5px;
          color: #1E1E1E;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
    
    
        }
    
        li::before {
          content: '';
          position: absolute;
          top: 1.5px;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
    
        li:nth-child(1)::before {
          background-color: #84d9bf;
        }
    
        li:nth-child(2)::before {
          background-color: #f56c7b;
        }
    
        li:nth-child(3)::before {
          background-color: #e3e3e3;
        }
      }
    
    
}

.boldText{
    font-weight: 700;
}
