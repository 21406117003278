.three-cards-top {
  &__container {
    background-color: #ffffff;
    display: flex;
    border-radius: 15px;
    padding: 16px 20px;
    min-width: 790px;
    :last-child {
      border-right: none;
      margin-right: 0px;
      padding-right: 0px;
    }
  }

  &__content {
    position: relative;
    border-right: 1px solid #f6f6f6;
    padding-left: 6px;
    padding-right: 20px;
    margin-right: 20px;
    flex-grow: 1;
  }

  &__title {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 14px;
  }

  &__data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &__main-percent {
    color: #2b2b2b;
    font-weight: 800;
    font-size: 24px;
  }

  &__change-percent {
    display: flex;
    justify-content: center;
    min-width: 52px;
    padding: 4px 8px 4px 6px !important;
    border-radius: 20px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }

  &__hover {
    position: absolute;
    top: -40px;
    right: 0;
  }

  &__sum {
    font-weight: 400;
    font-size: 14px;
    color: #9c9c9c;
  }

  &__chart-container {
    padding-top: 10px;
  }

  &__button-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    padding-top: 25px;
    background-color: #ffffff;

    p {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.three-cards-top__btn-enter {
  opacity: 0;
}
.three-cards-top__btn-enter-active {
  opacity: 1;
  animation: hide-btn 0.3s forwards;
}
.athree-cards-top__btnlert-exit {
  opacity: 1;
}
.three-cards-top__btn-exit-active {
  animation: show-btn 0.3s forwards;
}

@keyframes show-btn {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
  }
}

@keyframes hide-btn {
  0% {
    opacity: 1;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.red {
  color: #f56c7b;
  background: linear-gradient(0deg, rgba(245, 108, 123, 0.2) 0%, rgba(245, 108, 123, 0.2) 100%),
    #fff;
}

.green {
  color: #84d9bf;
  background: linear-gradient(0deg, rgba(132, 217, 191, 0.2) 0%, rgba(132, 217, 191, 0.2) 100%),
    #fff;
}

.gray {
  color: #1e1e1e;
  background: #f4f4f4;
}
