$color-green-dark: #76c431;
$color-green-light: #21da67;

$color-gray-dark: #3c4449;
$color-gray: rgb(78, 78, 84);
$color-gray-light: #e5ebef;
$color-gray-extra-light: whitesmoke;

$color-red: #e24b41;

$color-purple-dark: #6445e0;
$color-purple-light: #8061ff;

$color-white: #ffffff;

$color-black: #29292f;
$color-silver: #c0c0c0;

$color-gray-subtitle: #9e9e9e;
$color-gray-muted: #f6f6f6;
$color-gray-title: #ededed;
$color-brown-light: #e5ddd5;

$message-green-color: #dcf8c6;

//new collors

$smak-color: #313940;
$color-blue: #1187f4;
$color-blue-light: rgb(93, 173, 248);
$color-blue-correct: rgba(17, 135, 244, 0.3);
$gradient: linear-gradient(101.34deg, #ffffff 6.4%, rgba(255, 255, 255, 0.3) 96.78%);
