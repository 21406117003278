.modal-sales {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__stat {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
