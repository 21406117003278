.nps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media(max-width: 1300px) {
    justify-content: space-between;
}


  &__item-empty{
    display: flex;
  }

}