.geosuggest__input{

    outline: 0;
    outline-offset: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 15px;
    font-size: 16px;
    width: 100%;
    height: 52px;
    background: #f9f9f9;
    transition: border 0.25s;
    border: none;
    border-radius: 10px;
    position: relative;
}

.geosagest-input__wrapper{
    border: 1px solid rgba(245, 108, 123, 1);
    border-radius: 10px;
}

.geosuggest__suggests{
    list-style: none;
    // padding: 5px;
    border-radius: 10px;
    position:absolute;
    z-index: 100;
    background-color: #FFFFFF;
}

.geosuggest__item{
    padding-top: 16px;
    padding-left: 14px;
}

.geosuggest__suggests--hidden{
    background-color: #FFFFFF;
    margin-top: 10px;

}