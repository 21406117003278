.toolTip_container {
  display: inline-flex;
  padding: 10px 15px 14px 15px;
  flex-direction: column;
  width: 440px;
  height: 93px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  position: absolute;
  z-index: 1;
  opacity: 1;
  bottom: -100px;
  left: 0px;
  border-radius: 7px;
  background:  #FFF;
  box-shadow: 2px 6px 10px 0px rgba(0, 0, 0, 0.09) ;
}

.toolTipText {
  color: #1e1e1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.toolTipBoldText {
  color: #1e1e1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
