.hover-settings__ {
  &gear {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
    &__day-result {
      display: flex;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  &info-container {
    display: flex;
    position: relative;
    &info-container-popover {
      position: absolute;
      z-index: 100;
      padding: 15px;
      top: 30px;
      left: 10px;
      color: #1e1e1e;
      background-color: #ffffff;
      border: none;
      border-radius: 15px;
      box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
      width: 400px;
      p {
        font-size: 12px;
      }
    }
  }
  .hover-settings__info-icon {
    margin-top: 0px;
    margin-left: 10px;
  }

  .hover-settings__info-icon {
    fill: #e3e3e3;
    transition: fill 0.3s ease;
  }

  .hover-settings__info-icon:hover {
    fill: #67a8f4;
  }

  .info-text {
    display: flex;
    flex-direction: column;
    &-formula {
      color: #67a8f4;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 800;
    }
    &-example {
      color: #9c9c9c;
      margin-top: 10px;
      max-width: 280px;
    }
    &-list {
      margin-left: 10px;
      margin-bottom: 20px;
      li {
        font-size: 12px;
        margin-left: 8px;
      }
    }
    &-about {
      margin-bottom: 10px;
    }
  }
}

.boldText {
  font-weight: 700;
}
