.hover-revenue_ {
  &container {
    display: flex;
    position: absolute;
    right: -225px;
    top: -50px;
    padding: 7px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  }

  &wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  &text {
    color: #1e1e1e;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &text-bold {
    display: flex;
    min-width: 50px;
    color: #1e1e1e;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
