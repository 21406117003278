.error {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 0px;
  
    &-text {
      margin-top: 10px;
      color: #9c9c9c;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }
  }

  .reviews{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 160px;
  }

  .withMargin{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
}
