.restaurants-table {
    &__container {
      position: relative;
      bottom: -26px;
    }
  
    &__wrapper {
      display: flex;
      gap: 15px;
    }
  
    &__data {
      border-right: 1px solid #f6f6f6;
      border-bottom: 1px solid #f6f6f6;
      display: flex;
      &-list {
        margin-right: 15px;
        border-radius: 15px;
        border-radius: 15px;
        background: white;
      }
    }
  
    &__header {
      display: flex;
      position: relative;
      gap: 15px;
      height: 45px;
  
      &-btns {
        display: flex;
  
        &-three-dots{
          margin-top: 1px;
        }
      }
      &-list {
        display: flex;
        overflow: hidden;
        color: #1e1e1e;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 5px;
      }
  
      &-item-wrapper {
        width: 160px;
      }
  
      &-item {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        color: #1e1e1e;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 160px;
        height: 33px;
        // max-height: 45px;
        max-height: 155px;
        overflow: hidden;
        white-space: normal;
        cursor: pointer;
  
        &:first-child {
          display: flex;
        }
  
        p {
          width: 110px;
        }
  
        &-top{
          font-weight: 700;
        }
      }
    }
  
    &__list {
      display: flex;
    }
  
    &__item {
      padding: 17.5px 10px;
      width: 160px;
      height: 50px;
      border-right: 1px solid #f6f6f6;
      border-bottom: 1px solid #f6f6f6;
      color: #1e1e1e;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  
  .rotate-right {
    transform: rotate(0deg);
    height: 100%;
  }
  
  .rotate-down {
    position: relative;
    top: -6px;
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
  }
  