.hover-info-table {
  &__container {
  }

  &__title {
    padding: 7px 9px;
    position: absolute;
    z-index: 123123;
    border-radius: 5px;
    background: #fff;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.08);
    bottom: 50px;
    right: 10px;
    width: 313px;
    color: #1e1e1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__icon {
    margin-top: 3px;
    margin-left:10px;
    fill: #e3e3e3;
    transition: fill 0.3s ease;

    &:hover {
      fill: #67a8f4;
    }
  }
}
