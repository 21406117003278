.reviews-content-header {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;

  &__filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:first-child {
      margin-left: 0px;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
  }
}
