.table-row {
    &__container {
    }
  
    &__wrapper {
      display: grid;
      // grid-template-columns: 33.5% 11.1% 11.1% 11.1% 11.1% 11.1% 11.1%;
      grid-template-columns: 22.77% 12.87% 12.87% 12.87% 12.87% 12.87% 12.87%;
      border-bottom: 1px solid #f6f6f6;
      border-bottom: 1px solid #f6f6f6;
      // padding: 10px 0px 7px 25px;
      align-items: center;
      justify-content: center;
    }
  
    &__main-title-row{
      color: #2b2b2b;
      font-weight: 700;
      font-size: 14px;
      padding-left: 30px !important;
      padding: 10px 0px 10px 30px;
    }
  
    &__title {
      color: #2b2b2b;
      font-size: 12px;
      font-weight: 700;
      padding: 10px 0px 10px 15px;
    }
  
    &__btn-wrapper {
      padding-left: 10px;
    }

    &__btn-wrapper-row {
      padding-left: 30px;
    }

    &__btn-wrapper-third-row {
      padding-left: 30px;
    }
  }
  
  .writeOff {
    display: grid;
    grid-template-columns: 43.15% 14.27% 14.27% 14.27% 14.27%;
    align-items: center;
    justify-content: center;
  }
  
  .writeOffSecond {
    display: grid;
    grid-template-columns: 43% 19% 19% 19%;
    align-items: center;
    justify-content: center;
  }
  