.top-banner {
    &__conatiner{
        background-color: #F9F9F9;
        border-radius: 8px;
        padding: 15px 14px 34px 14px;
        margin-top: 11px;
    }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-percent {
      color: #2b2b2b;
      font-weight: 800;
      font-size: 30px;
      line-height: 38px;
      padding-right: 10px;
    }
    &-title {
      margin-top: 5px;
      color: #2b2b2b;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      max-width: 360px;
      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

