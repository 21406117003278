.table-cheque {
  &__container {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    border-bottom: 1px solid #f6f6f6;
    margin: 14px 30px 0px 30px;
    padding-bottom: 10px;
  }

  &__name {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #9c9c9c;
  }
  &__table {
    display: flex;
    padding-right: 20px;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #9c9c9c;
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #9c9c9c;
    padding-right: 30px;
  }

  &__sum {
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #9c9c9c;
  }

  &__about {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #9c9c9c;
  }

  &__wrapper {
    border-bottom: 1px solid #f6f6f6;
    margin: 0px 30px 30px 30px;
    padding-bottom: 10px;
  }
}
