.loading{

    &-container{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background: linear-gradient(152deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #67A8F4;
        background-size: 200%;
        animation: gradient 0.8s ease infinite;
    }


@keyframes gradient {
    0% {
      background-position: 0 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
  
    100% {
      background-position: 0 50%;
    }
  }
  
}