.foodcost-hover-info {
  &__container {
    position: absolute;
    z-index: 100;
    padding: 4px 10px;
    top: -34px;
    right: 20px;
    color: #1e1e1e;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.08);
    width: 155px;
  }
  &__text {
    color: #1e1e1e;
    text-align: center;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
