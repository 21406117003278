.reviews-window-content {
  &-container {
    display: flex;
    flex-direction: row;
    margin: 16px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.reviews-content {
  &__container {
    border-bottom: 1px solid #f6f6f6;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #C4C4C2 ;
  }
  &__container::-webkit-scrollbar {
    width: 2px;
  }


  &__container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #C4C4C2;
  }
}
