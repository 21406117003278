.tofu-qr-download {
  &__container {
    padding: 75px 0px 0px 0px;
    width: 1800px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 75px;

    img {
      height: 321px;
      width: 321px;
      border-radius: 50%;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    gap: 10px;
  }

  &__wrapper-first {
    display: flex;
  }

  &__wrapper-second {
    display: flex;
    justify-content: flex-end;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  &__wrapper-third {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-first {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      font-size: 72px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #67a8f4;
      padding-top: 90px;
      padding-bottom: 90px;
      width: 893px;
      height: 231px;
      border-radius: 150px;
    }

    &-second {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      text-align: center;
      font-size: 72px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #fff;
      border-radius: 25px;
      padding-top: 90px;
      padding-bottom: 90px;
      width: 1207px;
      height: 231px;
      border-radius: 150px;
    }

    &-third {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
      font-size: 72px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #0075ff;
      padding-top: 90px;
      padding-bottom: 90px;
      width: 1518px;
      height: 231px;
      border-radius: 150px;
    }
  }
  &__code {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 108px;
      margin-bottom: 120px;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #fff;
      border-radius: 150px;
      padding-bottom: 10px;
      width: 1518px;

      p {
        margin-top: 120px;
        color: #1e1e1e;
        text-align: center;
        font-size: 60px;
        font-weight: 700;
        line-height: 120%; /* 12px */
        text-transform: uppercase;
      }

      img{
        margin-bottom: 80px;
      }
    }
  }

  &.oneToOne {
    &__container {
      background-image: url('../../../../../images/settings/reviewsPlatform/nine-to-sixteen-blue.jpg');
      padding: 17px 20px 20px 20px;
      width: 300px;
      background-size: cover;
    }

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      img {
        height: 53px;
        width: 53px;
        border-radius: 50%;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;

      &-wrapper-first {
        display: flex;
      }

      &-wrapper-second {
        display: flex;
        justify-content: flex-end;
        margin-top: 12.5px;
        margin-bottom: 12.5px;
      }

      &-wrapper-third {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-first {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: #67a8f4;
        border-radius: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 140px;
      }

      &-second {
        display: flex;
        align-content: center;
        justify-content: center;
        color: #000;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: #fff;
        border-radius: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 202px;
      }

      &-third {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: #0075ff;
        border-radius: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 259px;
      }
    }
    &__code {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12.5px;
      flex-direction: column;
      background-color: #fff;
      border-radius: 25px;

      p {
        margin-top: 20px;
        color: #1e1e1e;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        line-height: 120%; /* 12px */
        text-transform: uppercase;
      }
    }
  }
}

// three to four

.threeToFourDownload {
}

.threeToFourLogoDownload {
  margin-bottom: 75px;
}

.threeToFourTextDownload {
}

.threeToFourWrapperFirstDownload {
}

.threeToFourWrapperSecondDownload {
  display: flex;
  justify-content: flex-end;
  margin-top: 54px;
  margin-bottom: 54px;
}

.threeToFourWrapperThirdDownload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.threeToFourTextFirstDownload {
  padding-top: 66px;
  padding-bottom: 66px;
  font-size: 54px;
  height: 170px;
}

.threeToFourTextSecondDownload {
  padding-top: 66px;
  padding-bottom: 66px;
  font-size: 54px;
  height: 170px;
}

.threeToFourTextThirdDownload {
  padding-top: 66px;
  padding-bottom: 66px;
  font-size: 54px;
  height: 170px;
}

.threeToFourCodeWrapper {
  margin-top: 71px;
}
.threeToFourCodeDownload {
  border-radius: 111px;
  p {
    margin-top: 81px;
    font-size: 54px;
  }

  img {
    margin-bottom: 60px;
  }
}

// one-to-one

.oneToOneDownload {
  padding: 60px 218px 0px 218px;
  display: flex;
  flex-direction: column;
  align-items: normal;
}

.oneToOneLogoDownload {
  margin-bottom: 60px;
}

.oneToOneTextDownload {
  margin-top: 9px;
  margin-bottom: 9px;
}

.oneToOneWrapperFirstDownload {
}

.oneToOneTextFirstDownload {
  font-size: 42px;
  padding-top: 45px;
  padding-bottom: 45px;
  height: 120px;
}

.oneToOneWrapperSecondDownload {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 0px;
}
.oneToOneTextSecondDownload {
  font-size: 42px;
  padding-top: 45px;
  padding-bottom: 45px;
  height: 120px;
}

.oneToOneWrapperThirdDownload {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 0px;
  width: auto;
}
.oneToOneTextThirdDownload {
  font-size: 42px;
  padding-top: 45px;
  padding-bottom: 45px;
  height: 120px;
}

.oneToOneCodeWrapper {
  margin-top: 60px;
}

.oneToOneCodeDownload {
  border-radius: 111px;
  p {
    margin-top: 81px;
    font-size: 54px;
  }

  img{
    margin-bottom: 39px;
  }
}
