.subscription-banner {
  &__container {
    background-image: url('../../../../../images/subscripthion/tofu-bg.jpg');
    border-radius: 10px;
    padding: 10px 10px 16px 20px;
    height: 95px;
  }

  &__wrapper {
    // display: flex;
    // justify-content: space-between;
    position: relative;
  }

  &__trial {
    position: absolute;
    right: 0;
    display: flex;

    &-text {
      background: #3dcb60;
      display: flex;
      border-radius: 4px;
      padding: 4px 6px;
      color: #ffffff;
      font-weight: 800;
      font-size: 10px;
      margin-left: 8px;
    }
  }

  &__logo {
    padding-top: 12px;
  }

  &__price {
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;

    span {
      color: #ffffff;
      font-weight: 800;
      font-size: 28px;
    }
  }
}
