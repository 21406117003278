.hover-desc__container {
  position: absolute;
  bottom: 33px;
  right: -45px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  color: #1e1e1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px 9px;
  width: 112px;
}
