.item-info {
  &__wrapper {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    align-items: center;
    margin: 14px 30px 0px 0px;
  }
  &-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2b2b2b;
  }
  &-table {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #2b2b2b;
  }

  &-time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #9c9c9c;
  }

  &-sum {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #2b2b2b;
  }

  &-arrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #2b2b2b;
    cursor: pointer;
  }
}
