.hover-plan {
  &_container {
    display: flex;

    padding: 7px 9px;
    width: 100px;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
  }
  &_text {
    display: flex;
    flex-direction: row;
    color: #1e1e1e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &_text-percent{
    padding-left: 5px;
    color: #1e1e1e ;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
