$primary-color: darkolivegreen;
$secondary-color: white;
$accent-color: pink;
$highlight-color: red;
$shadow-color: black;
$delete-color: red;
$close-bg-color: lightblue;

.calendar {
  &-wrapper {
    margin-top: 20px;
    background: #fff;
    padding: 16px 20px;
    border-radius: 15px;
    margin-bottom: 40px;
  }

  &-header {
    display: flex;
    justify-content: space-between;

    &__text {
      h2 {
        color: #2b2b2b;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
      }

      p {
        color: #9c9c9c;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__btn{
        display: flex;
        align-items: flex-end;
    }
  }

  &-arrow-left{
    transform: rotate(90deg);
    margin-right: 5px;
    cursor: pointer;
  }

  &-arrow-right{
    transform: rotate(270deg);
    margin-left: 5px;
    cursor: pointer;
  }

  &-date-controls {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    align-items: center;

    ion-icon {
      font-size: 1.6rem;
      cursor: pointer;
    }
  }

  &-header-colmns {
    border: 1px solid transparent;
    height: 60px;
    // width: 140px;
    background: #f9f9f9;
    color: #9c9c9c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  &-seven-col-grid {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;

    &.fullheight {
      height: calc(100% - 75px);

      &.is28Days {
        grid-template-rows: repeat(4, 1fr);
      }

      div {
        display: grid;
        border: 1px solid;

        .styled-event {
          display: none;
        }

        .styled-event:nth-child(-n + 3) {
          display: block;
        }

        span {
          text-align: right;
          padding-right: 15px;
          height: fit-content;
        }

        span.active {
          background-color: pink;
          border-bottom: 2px solid red;
          position: relative;

          &::before {
            content: 'Today ';
            font-size: 14px;
          }
        }
      }
    }
  }

  &-column {
    border-radius: 6px;
    border: 1px solid rgba(240, 246, 254);
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 140px;
    height: 60px;
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(13),
    &:nth-child(14),
    &:nth-child(20),
    &:nth-child(21),
    &:nth-child(27),
    &:nth-child(28),
    &:nth-child(34),
    &:nth-child(35) {
        border-radius: 6px;
        border: 1px solid  #F0F6FE;
        background: #F0F6FE;
        color: #67A8F4;
    }
  }
}

.wrapper {
  .styled-event {
    background: $accent-color;
    color: $secondary-color;
    text-align: left !important;
    padding: 2px 10px;
    margin: 0 2px;
    border-radius: 10px;
    font-size: 13px;
    cursor: move;
    text-transform: capitalize;
  }

  .head-days {
    text-align: center;
    border: 1px solid;
    height: 30px;
    padding: 5px;
    background: $primary-color;
    color: $secondary-color;
  }

  .see-more {
    font-size: 12px;
    padding: 0 5px;
    margin-bottom: 0;
    cursor: pointer;
  }

  .portal-wrapper {
    background: $secondary-color;
    position: absolute;
    width: 60%;
    height: 200px;
    top: 50%;
    left: 50%;
    border-radius: 6px;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 20px $shadow-color;
    padding: 40px;

    h2 {
      font-size: 3rem;
    }

    ion-icon {
      font-size: 2rem;
      color: $delete-color;
      background: $close-bg-color;
      padding: 10px 20px;
      border-radius: 6px;
    }

    p {
      margin-bottom: 15px;
    }

    ion-icon[name='close-outline'] {
      position: absolute;
      top: 10px;
      right: 10px;
      background: $delete-color;
      color: $close-bg-color;
    }
  }
}

.calendar-column.clicked {
  background-color: #67A8F4;

  span{
    color: #FFF;
  }
}