.table-section {
  &__container {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    justify-content: center;
    border-radius: 15px;
  }

  &__wrapper {
    display: grid;
    // grid-template-columns: 33.5% 11.1% 11.1% 11.1% 11.1% 11.1% 11.1%;
    grid-template-columns: 22.77% 12.87% 12.87% 12.87% 12.87% 12.87% 12.87%;
    border-bottom: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
    // padding: 10px 0px 10px 25px;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 800;
    padding: 20px 0px 20px 15px;

    &:first-child {
      font-size: 16px;
    }
  }

  &__main-title {
    display: flex;
    padding-left: 10px;
  }

  &__close {
    display: none;
  }
}

.writeOff {
  display: grid;
  grid-template-columns: 43.15% 14.27% 14.27% 14.27% 14.27%;
  align-items: center;
  justify-content: center;
}

.writeOffSecond {
  display: grid;
  grid-template-columns: 43% 19% 19% 19%;
  align-items: center;
  justify-content: center;
}
