.table {
  &__title {
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }

  &__list-wrapper {
    border-top-left-radius: 12px;
  }

  &__wrapper {
    border-radius: 15px;
  }

  &__error {
    position: relative;
    bottom: -40px;
    height: 200px;
  }

  &__selects {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24.5px;

    &-select {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }

  &__header {
    display: grid;
    justify-content: space-between;
    &.sticky {
      background-color: #f6f6f6;
      position: sticky !important;
      top: 0;
      z-index: 100;
      height: 36px;
    }
    align-items: center;

    &-settings {
      position: relative;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;

      &__title {
        color: #9c9c9c;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &__icon {
        margin-top: 3px;
        cursor: pointer;
      }

      &__hover {
        width: 151px;
        bottom: 23px;
        right: -65px;
        position: absolute;
        padding: 7px 9px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 2px 6px 20px 0px rgba(0, 0, 0, 0.09);
        color: #1e1e1e;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &-group {
      &__container {
        display: flex;
        padding: 10px 14px;
        flex-direction: column;
        border-left: 1px solid white;
        border-right: 1px solid white;
        border-top: 1px solid white;
        border-radius: 15px 15px 0px 0px;
        width: 99%;
      }

      &__title {
        display: flex;
        color: #9c9c9c;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 5.5px;

        &-title {
          color: #1e1e1e;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          display: flex;
        }

        &-stat {
          color: #67a8f4;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      &__wrapper {
        display: flex;
        align-items: center;
        gap: 36px;
      }
    }

    &-title {
      color: #2b2b2b;
      font-size: 18px;
      font-weight: 700;
    }

    &-delete {
      color: #9c9c9c;
      font-size: 12px;
      font-weight: 400;
      align-items: center;
      display: flex;

      &::before {
        content: '';
        position: relative;
        margin-right: 4px;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
      }
    }

    &-search {
      display: flex;
      gap: 40px;
    }
  }

  &__column {
    &-wrapper {
      display: grid;
      position: sticky;
      top: 0;
      height: 20px;
      grid-template-columns: 22.35% 13% 12.9% 13.1% 13.05% 13.11% 11.5%;
      align-items: center;
      justify-content: center;
      padding-left: 21px;
    }
    &-hover-info {
      margin-top: -20px;
    }
    &-title {
      color: #2b2b2b;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      //   padding-left: 15px;
      display: flex;
      align-items: center;
      &:first-child {
        padding-left: 25px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    gap: 10px;
  }
}

.writeOff {
  display: grid;
  grid-template-columns: 43.15% 14.27% 14.27% 14.27% 14.27%;
  align-items: center;
  justify-content: center;
}

.writeOffSecond {
  display: grid;
  grid-template-columns: 43% 19% 19% 19%;
  align-items: center;
  justify-content: center;
}

.isHiddenWrapper {
  display: none !important;
}

.isMenuOpenTable {
  padding: 10px 24px;
}
